import React, { useEffect, useRef } from "react";
import { useTranslation, Trans } from 'react-i18next';


const Kv = () => {
    const { t, i18n } = useTranslation();

    const layerRefs = useRef([]);
    const kv_phone = `/images/kv_phone_${i18n.language}.png`;

    return (
        <div className="kv">
            <div
                data-speed="0.05"
                ref={(el) => (layerRefs.current[0] = el)}
            ><img src="/images/kv_flight.png" alt="" /></div>
            <div
                data-speed="0"
                ref={(el) => (layerRefs.current[1] = el)}
            ><img src={kv_phone} alt="" /></div>
            <div
                data-speed="-0.025"
                ref={(el) => (layerRefs.current[2] = el)}
                className="prize-1"
            ><img src="/images/kv_prize_1.png" alt="" /></div>
            <div
                data-speed="-0.025"
                ref={(el) => (layerRefs.current[3] = el)}
                className="prize-2"
            ><img src="/images/kv_prize_2.png" alt="" /></div>
            <div
                data-speed="0"
                ref={(el) => (layerRefs.current[1] = el)}
            ><img src="/images/kv_photos.png" alt="" /></div>
            <div
                data-speed="-0.2"
                ref={(el) => (layerRefs.current[4] = el)}
                className="coins-1"
            ><img style={{transform: 'translateY(-4%)'}} src="/images/kv_badge.png" alt="" /></div>
            {/* <div
                data-speed="-0.2"
                ref={(el) => (layerRefs.current[4] = el)}
                className="coins-2"
            ><img src="/images/kv_coins_2.png" alt="" /></div> */}
        </div>
    );
};

export default Kv;
