import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import translationEN from './locales/en/translation.json';
import translationTC from './locales/tc/translation.json';
import translationSC from './locales/sc/translation.json';

// Define the translations
const resources = {
  en: {
    translation: translationEN,
  },
  tc: {
    translation: translationTC,
  },
  sc: {
    translation: translationSC,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'tc', // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    }
  });

export default i18n;