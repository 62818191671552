import React, { useContext, useState, useEffect } from "react";
import { QuizContext } from "../context/QuizContext.js";
import { AppContext } from "../context/AppContext.js";
import ImagesList from "../data.js";
import { useTranslation, Trans } from "react-i18next";

const DisplayState = () => {
    const { state } = useContext(QuizContext);

    const types = [
        "efficiency",
        "shopaholic",
        "business",
        "foodie",
        "pamperer",
    ];

    return (
        <div className="fixed top-0 left-0 text-white z-[10] pointer-events-none">
            <h2>Current Question: {state.currentQuestion + 1}</h2>
            <h3>
                Scores:
                {state.scores.map((score, index) => {
                    return (
                        <span key={index}>
                            {types[index]}: {score}{" "}
                        </span>
                    );
                })}
            </h3>
            <h3>Result idx: {state.resultIndex}</h3>
            <h3>Result id: {state.resultId}</h3>
        </div>
    );
};

const LoadingScreen = () => {
    const { state, dispatch } = useContext(AppContext);
    const { t, i18n } = useTranslation();
    const [loadedImages, setLoadedImages] = useState(0);
    const [loadedPercentage, setLoadedPercentage] = useState(0);
    const totalImages = ImagesList.length;

    let loadingImagesLength = 0;

    useEffect(() => {
      preloadImags();
  }, []);

    useEffect(() => {
        setLoadedPercentage((loadedImages / totalImages) * 100);
        // console.log(loadedImages, totalImages);
        if (loadedImages >= ImagesList.length - 1) {
            dispatch({ type: "SET_LOADING", payload: false });
        }
    }, [loadedImages]);

    const preloadImags = () => {
        dispatch({ type: "SET_LOADING", payload: true });
        ImagesList.forEach((src) => {
            const link = document.createElement("link");
            link.rel = "preload";
            link.href = src;
            link.as = "image";
            document.head.appendChild(link);

            const img = new Image();
            img.src = src;
            img.onload = () => {
                loadingImagesLength++;
                setLoadedImages(loadingImagesLength);
            };
        });
        // force hide loading screen after 5s
        setTimeout(() => {
            dispatch({ type: "SET_LOADING", payload: false });
        }, 2000);
    };
    
    return (
        <div
            className="loading-screen"
            style={{ display: state.isLoading ? "flex" : "none" }}
        >
            {/* <div className="loading-bar-bg">
                <div
                    className="loading-bar"
                    style={{ width: `${loadedPercentage}%` }}
                ></div>
            </div> */}
            {t('loading')}
        </div>
    );
};

export { DisplayState, LoadingScreen };
