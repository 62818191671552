import React, { useState, useContext } from "react";
import { useTranslation, Trans } from 'react-i18next';
import Kv from '../components/Kv';
import LanguageSwitcher from '../components/LanguageSwitcher';

const Announcement = ({onChangePage}) => {
    const { t, i18n } = useTranslation();

    return (
        <div className='page page-announcement' style={{
            backgroundImage: 'url("/images/bg-home.jpg")'
        }}>
            <div className="bg" style={{backgroundImage: 'url("/images/bg-home-repeat.jpg")'}}></div>
            <div className='absolute top-sm right-md flex justify-end z-10' >
                <div className="text-[14px] flex gap-[4px]">
                    <button className={ i18n.language === 'en'?'underline': '' } onClick={()=>{i18n.changeLanguage('en')}}>EN</button>
                    <span>/</span> 
                    <button className={ i18n.language === 'tc'?'underline': '' } onClick={()=>{i18n.changeLanguage('tc')}}>繁</button>
                    <span>/</span>
                    <button className={ i18n.language === 'sc'?'underline': '' } onClick={()=>{i18n.changeLanguage('sc')}}>簡</button>
                </div>
            </div>
            <div className="pt-lg">
                <div className="kv-container">
                    <Kv />
                </div>
            </div>

            <div className="pb-lg">

                <section className="mb-lg">
                    <div className="inner">
                        <h2 className="gold-box text-md leading-tight font-bold">
                            <Trans i18nKey='end.heading' components={{br: <br />}}/>
                        </h2>
                    </div>
                </section>
                <section className="my-lg">
                    <div className="inner">
                        <h2 className="mb-sm text-md leading-tight">
                            <Trans i18nKey='end.efficiency' components={{br: <br />}}/>
                        </h2>
                        <ul>
                        <li>Cathy Tsang (Facebook)</li>
                        <li>Cheuk Yin Choi (Facebook)</li>
                        <li>CheukWang Lee (Facebook)</li>
                        <li>Cowcow Pkmg (Facebook)</li>
                        <li>DaDa Wong (Facebook)</li>
                        <li>Hok Kong (Facebook)</li>
                        <li>John Leung (Facebook)</li>
                        <li>KF Leung (Facebook)</li>
                        <li>Maisy Olausen (Facebook)</li>
                        <li>Terry Wong (Facebook)</li>
                        <li>윤용심 (Facebook)</li>
                        <li>谷山臣漢 (Facebook)</li>
                        <li>1223_nhl (Instagram)</li>
                        <li>ching.elaine.5 (Instagram)</li>
                        <li>cki5.05 (Instagram)</li>
                        <li>hohoson0222 (Instagram)</li>
                        <li>hok__kong (Instagram)</li>
                        <li>ildschwein (Instagram)</li>
                        <li>imboyiii (Instagram)</li>
                        <li>jolie.jelly (Instagram)</li>
                        <li>kathy.kayan.5 (Instagram)</li>
                        <li>kingsley._.photography (Instagram)</li>
                        <li>lijing9067 (Instagram)</li>
                        <li>reikaxtion (Instagram)</li>
                        <li>rougherliver (Instagram)</li>
                        <li>snorlax8848 (Instagram)</li>
                        <li>supermimimimimimi (Instagram)</li>
                        <li>tinacheung77 (Instagram)</li>
                        <li>traveller16899 (Instagram)</li>
                        <li>wordsmith_warlock (Instagram)</li>
                        <li>xmemoriesx (Instagram)</li>
                        <li>scottmanpro (Instagram)</li>
                        <li>ferdiie_wang (Instagram)</li>
                        </ul>
                    </div>
                </section>
                <section className="my-lg">
                    <div className="inner">
                        <h2 className="mb-sm text-md leading-tight">
                            <Trans i18nKey='end.shopaholic' components={{br: <br />}}/>
                        </h2>
                        <ul>
                        <li>Alexson Chan (Facebook)</li>
                        <li>Au Kong (Facebook)</li>
                        <li>Cathy Leung (Facebook)</li>
                        <li>Cheuk Wing Lau (Facebook)</li>
                        <li>Chi Keung Chow (Facebook)</li>
                        <li>Chi Ting Leung (Facebook)</li>
                        <li>Chow Lou (Facebook)</li>
                        <li>Chow Tat Ming (Facebook)</li>
                        <li>Davis Lau (Facebook)</li>
                        <li>Destiny Lau (Facebook)</li>
                        <li>Eri NG (Facebook)</li>
                        <li>Fances Lau (Facebook)</li>
                        <li>Fun Fan (Facebook)</li>
                        <li>Gaia Lau (Facebook)</li>
                        <li>Hoi Ki Lam (Facebook)</li>
                        <li>Hong Hong Ng (Facebook)</li>
                        <li>Jack SO (Facebook)</li>
                        <li>Jetso Ng (Facebook)</li>
                        <li>Joe Chow (Facebook)</li>
                        <li>Ka Kui Siu (Facebook)</li>
                        <li>Kay Tang (Facebook)</li>
                        <li>Kei Kei Chan (Facebook)</li>
                        <li>Kei Tan (Facebook)</li>
                        <li>Keung Keung (Facebook)</li>
                        <li>Kevin Chan (Facebook)</li>
                        <li>Ki Ki Leung (Facebook)</li>
                        <li>Kika Chow (Facebook)</li>
                        <li>Kim Chi Chow (Facebook)</li>
                        <li>Koko Chau (Facebook)</li>
                        <li>Lau Adam (Facebook)</li>
                        <li>Ling Ling Leung (Facebook)</li>
                        <li>Mayuyu Kwong (Facebook)</li>
                        <li>Miku Leung (Facebook)</li>
                        <li>Minnie Leung (Facebook)</li>
                        <li>Miru Chow (Facebook)</li>
                        <li>Momoka Lau (Facebook)</li>
                        <li>On Yi Cheng (Facebook)</li>
                        <li>Pang Cathy (Facebook)</li>
                        <li>Parker Lau (Facebook)</li>
                        <li>Risa Chan (Facebook)</li>
                        <li>San Chi Lau (Facebook)</li>
                        <li>Sarah Chow (Facebook)</li>
                        <li>Sashi Chan (Facebook)</li>
                        <li>Sashihara Chow (Facebook)</li>
                        <li>SF LAu (Facebook)</li>
                        <li>Shin Shin Chan (Facebook)</li>
                        <li>Shirley Lee (Facebook)</li>
                        <li>Shui Fun Leung (Facebook)</li>
                        <li>Shui San Shui (Facebook)</li>
                        <li>Suki Suki Chow (Facebook)</li>
                        <li>Sunny Chow (Facebook)</li>
                        <li>Suzuki Chan (Facebook)</li>
                        <li>Ting Chow (Facebook)</li>
                        <li>Ting Ting Chow (Facebook)</li>
                        <li>Tong Tom (Facebook)</li>
                        <li>Valen Hung (Facebook)</li>
                        <li>Wai Kei Ng (Facebook)</li>
                        <li>Wai Sea Wong (Facebook)</li>
                        <li>Wing Nako (Facebook)</li>
                        <li>Wing San Chau (Facebook)</li>
                        <li>Wing Wing Kwong (Facebook)</li>
                        <li>Yan Wai Chow (Facebook)</li>
                        <li>Yi On Pang (Facebook)</li>
                        <li>Yin Chow (Facebook)</li>
                        <li>Ying Tung Chan (Facebook)</li>
                        <li>Yokohama Chan (Facebook)</li>
                        <li>Yokono Ng (Facebook)</li>
                        <li>Yoyo Chow (Facebook)</li>
                        <li>Yu Yuri (Facebook)</li>
                        <li>Yuki Kwong (Facebook)</li>
                        <li>aprilyan.leung (Instagram)</li>
                        <li>beebenbee19 (Instagram)</li>
                        <li>bennychan0327 (Instagram)</li>
                        <li>betterysab34 (Instagram)</li>
                        <li>chanchingching789 (Instagram)</li>
                        <li>chanfan8712 (Instagram)</li>
                        <li>chansan5722 (Instagram)</li>
                        <li>chichi.chan.58118 (Instagram)</li>
                        <li>chow69011 (Instagram)</li>
                        <li>chowrino (Instagram)</li>
                        <li>chowsansansan (Instagram)</li>
                        <li>chrischris3415 (Instagram)</li>
                        <li>hoifunchan (Instagram)</li>
                        <li>hoiyiyiyi34 (Instagram)</li>
                        <li>isaisasan0 (Instagram)</li>
                        <li>jackjackchow092 (Instagram)</li>
                        <li>kamchoisan (Instagram)</li>
                        <li>kotkotkachn (Instagram)</li>
                        <li>kwongching092 (Instagram)</li>
                        <li>lau72701 (Instagram)</li>
                        <li>mahokoo22 (Instagram)</li>
                        <li>mallowchanng (Instagram)</li>
                        <li>maskchau (Instagram)</li>
                        <li>melchi_milty (Instagram)</li>
                        <li>michellerusa12 (Instagram)</li>
                        <li>nako.chan.9847 (Instagram)</li>
                        <li>rikaschan092 (Instagram)</li>
                        <li>sflauuuu (Instagram)</li>
                        <li>shuilauu (Instagram)</li>
                        <li>yeung.saimui (Instagram)</li>
                        </ul>
                    </div>
                </section>
                <section className="my-lg">
                    <div className="inner">
                        <h2 className="mb-sm text-md leading-tight">
                            <Trans i18nKey='end.business' components={{br: <br />}}/>
                        </h2>
                        <ul>
                        <li>Adam Lee (Facebook)</li>
                        <li>AK Cheong (Facebook)</li>
                        <li>Kochakorn Wiriyamontri (Facebook)</li>
                        <li>Kwok Yuen Han Lussi (Facebook)</li>
                        <li>Tracy CY (Facebook)</li>
                        <li>許子寧 (Facebook)</li>
                        <li>aussie.shopping.hk (Instagram)</li>
                        <li>barotriyank (Instagram)</li>
                        <li>boo.thepugu (Instagram)</li>
                        <li>chan.ming.6 (Instagram)</li>
                        <li>charlene.whl (Instagram)</li>
                        <li>currtus (Instagram)</li>
                        <li>davesoncheung (Instagram)</li>
                        <li>edwin7410 (Instagram)</li>
                        <li>lanji_accessories (Instagram)</li>
                        <li>lovelovefish616 (Instagram)</li>
                        <li>mokuhyou.rin (Instagram)</li>
                        <li>nichyau (Instagram)</li>
                        <li>scx666 (Instagram)</li>
                        <li>siutok (Instagram)</li>
                        <li>smileluvsummer (Instagram)</li>
                        <li>turtle_fabric (Instagram)</li>
                        <li>zikomanidolac (Instagram)</li>
                        </ul>
                    </div>
                </section>
                <section className="my-lg">
                    <div className="inner">
                        <h2 className="mb-sm text-md leading-tight">
                            <Trans i18nKey='end.foodie' components={{br: <br />}}/>
                        </h2>
                        <ul>
                        <li>Alex Chan (Facebook)</li>
                        <li>Alvin Au (Facebook)</li>
                        <li>Belle Chan (Facebook)</li>
                        <li>Bing Ling (Facebook)</li>
                        <li>Chan Wingwing (Facebook)</li>
                        <li>Cheung Jelly (Facebook)</li>
                        <li>Chin Wong (Facebook)</li>
                        <li>Choi Yi Hui (Facebook)</li>
                        <li>Daisy Chan (Facebook)</li>
                        <li>Ethan Tseung (Facebook)</li>
                        <li>Felix Chan (Facebook)</li>
                        <li>Gary Chan (Facebook)</li>
                        <li>Jai Amigo (Facebook)</li>
                        <li>Jessica Lee (Facebook)</li>
                        <li>KamHo Lee (Facebook)</li>
                        <li>KaShing Wong (Facebook)</li>
                        <li>Kwok Hei (Facebook)</li>
                        <li>Lee CY (Facebook)</li>
                        <li>Lin Ming (Facebook)</li>
                        <li>Matthew Hei (Facebook)</li>
                        <li>Maymay Wong (Facebook)</li>
                        <li>Mickel Wong (Facebook)</li>
                        <li>Miller Hansen (Facebook)</li>
                        <li>Nicole Lee (Facebook)</li>
                        <li>Object Green Wai (Facebook)</li>
                        <li>Peii Pe (Facebook)</li>
                        <li>Riley Lee (Facebook)</li>
                        <li>Rose Yeung (Facebook)</li>
                        <li>Sabrina Ng (Facebook)</li>
                        <li>Sherry Sherry (Facebook)</li>
                        <li>Shinkansen Suki (Facebook)</li>
                        <li>Soda Shan (Facebook)</li>
                        <li>Tsui Hang Yiu (Facebook)</li>
                        <li>Windsy Li (Facebook)</li>
                        <li>Wing Fu (Facebook)</li>
                        <li>Yuni Cheang (Facebook)</li>
                        <li>2f.oj (Instagram)</li>
                        <li>alex_chu65 (Instagram)</li>
                        <li>bobo.eathk (Instagram)</li>
                        <li>bright_00_sun (Instagram)</li>
                        <li>car9_son (Instagram)</li>
                        <li>cat747_cargo (Instagram)</li>
                        <li>charliebb_cavapoo (Instagram)</li>
                        <li>cheukhimng.0215 (Instagram)</li>
                        <li>chinwong6442 (Instagram)</li>
                        <li>cymy_97 (Instagram)</li>
                        <li>dccccyk (Instagram)</li>
                        <li>debeccacaca (Instagram)</li>
                        <li>dic0527 (Instagram)</li>
                        <li>dusttobestar (Instagram)</li>
                        <li>enjoybellspatisserie (Instagram)</li>
                        <li>evolta_chan (Instagram)</li>
                        <li>fall.into.thai (Instagram)</li>
                        <li>flyaxeteam29 (Instagram)</li>
                        <li>fung_519 (Instagram)</li>
                        <li>hami_lala231 (Instagram)</li>
                        <li>happinesslambo (Instagram)</li>
                        <li>hellokitty_waiwai (Instagram)</li>
                        <li>hellotweetychung (Instagram)</li>
                        <li>i_v_a_n_kwong (Instagram)</li>
                        <li>iandbbbrown (Instagram)</li>
                        <li>ilovechip_n_dale (Instagram)</li>
                        <li>iris.ychan (Instagram)</li>
                        <li>issaclam2000 (Instagram)</li>
                        <li>jenjen_yoga (Instagram)</li>
                        <li>jungle_johnb (Instagram)</li>
                        <li>kitson129 (Instagram)</li>
                        <li>kitsune0917 (Instagram)</li>
                        <li>kmmmll0 (Instagram)</li>
                        <li>loveshare.foodie (Instagram)</li>
                        <li>manseaman (Instagram)</li>
                        <li>marcs2 (Instagram)</li>
                        <li>meow_lolai (Instagram)</li>
                        <li>microcream (Instagram)</li>
                        <li>miller_tofu (Instagram)</li>
                        <li>mingming1388888 (Instagram)</li>
                        <li>miss_americana_seer (Instagram)</li>
                        <li>mmmmandy_kt (Instagram)</li>
                        <li>moekwok2 (Instagram)</li>
                        <li>nataliewrv (Instagram)</li>
                        <li>pand.alalala (Instagram)</li>
                        <li>princeseaxd (Instagram)</li>
                        <li>purinbertha (Instagram)</li>
                        <li>s961217 (Instagram)</li>
                        <li>sam_jaiiiii (Instagram)</li>
                        <li>saucechimchim (Instagram)</li>
                        <li>siahsiahhh (Instagram)</li>
                        <li>siukeung672000 (Instagram)</li>
                        <li>sskamkam (Instagram)</li>
                        <li>sukishinkansen (Instagram)</li>
                        <li>sumbaholic (Instagram)</li>
                        <li>swswind (Instagram)</li>
                        <li>t0819y (Instagram)</li>
                        <li>torebalovelovekutar (Instagram)</li>
                        <li>trabbk (Instagram)</li>
                        <li>waiwaimike (Instagram)</li>
                        <li>whl90317 (Instagram)</li>
                        <li>yobbyo (Instagram)</li>
                        <li>yoooooooooooo222222 (Instagram)</li>
                        <li>yungtwins (Instagram)</li>
                        </ul>
                    </div>
                </section>
                <section className="my-lg">
                    <div className="inner">
                        <h2 className="mb-sm text-md leading-tight">
                            <Trans i18nKey='end.pamperer' components={{br: <br />}}/>
                        </h2>
                        <ul>
                        <li>Benny Kwan (Facebook)</li>
                        <li>Bing Kong Lam (Facebook)</li>
                        <li>Budkee Karen Wong (Facebook)</li>
                        <li>Ca Fish (Facebook)</li>
                        <li>CaCa Chan (Facebook)</li>
                        <li>Cfy Chau (Facebook)</li>
                        <li>Chan May (Facebook)</li>
                        <li>Dicky Ho (Facebook)</li>
                        <li>Elli Lot (Facebook)</li>
                        <li>Eunice Tse (Facebook)</li>
                        <li>Gigi Li (Facebook)</li>
                        <li>Heihei Kwok (Facebook)</li>
                        <li>Ho Ho Ho (Facebook)</li>
                        <li>Johua Chan (Facebook)</li>
                        <li>Kam Pui Leung (Facebook)</li>
                        <li>Kathy Ky Yan (Facebook)</li>
                        <li>Kelly Fung (Facebook)</li>
                        <li>Lin Chau (Facebook)</li>
                        <li>Miki Chan (Facebook)</li>
                        <li>Ming Chan (Facebook)</li>
                        <li>Nil Nil (Facebook)</li>
                        <li>Po Li (Facebook)</li>
                        <li>Rachel Tam (Facebook)</li>
                        <li>Raymond Lo (Facebook)</li>
                        <li>Sai Mui Yeung (Facebook)</li>
                        <li>Simon Poon (Facebook)</li>
                        <li>Sin Yu Leung (Facebook)</li>
                        <li>SY Yeung (Facebook)</li>
                        <li>Tay Aqua (Facebook)</li>
                        <li>_kentonese (Instagram)</li>
                        <li>_sy_yeung_ (Instagram)</li>
                        <li>0130.ray (Instagram)</li>
                        <li>1___13.d (Instagram)</li>
                        <li>adiac9319 (Instagram)</li>
                        <li>agneffggsig0001 (Instagram)</li>
                        <li>ansquaree (Instagram)</li>
                        <li>benk8282001 (Instagram)</li>
                        <li>berni.lai (Instagram)</li>
                        <li>blaiee.11152327 (Instagram)</li>
                        <li>brandy_0204 (Instagram)</li>
                        <li>bt456345 (Instagram)</li>
                        <li>cat_zong_ (Instagram)</li>
                        <li>cat.random.photos (Instagram)</li>
                        <li>chiisiiii (Instagram)</li>
                        <li>crochet_bubu (Instagram)</li>
                        <li>dogfight_funny (Instagram)</li>
                        <li>donxiaobaobao (Instagram)</li>
                        <li>dull728 (Instagram)</li>
                        <li>eat_play_80sgirl (Instagram)</li>
                        <li>fateh0607_ (Instagram)</li>
                        <li>fungfung_0412 (Instagram)</li>
                        <li>gla.igig (Instagram)</li>
                        <li>hengzhousg (Instagram)</li>
                        <li>holly_yeuk (Instagram)</li>
                        <li>honeysha13 (Instagram)</li>
                        <li>ichan784 (Instagram)</li>
                        <li>jessica.yeelee (Instagram)</li>
                        <li>kuenkuen_w (Instagram)</li>
                        <li>ladylagirls (Instagram)</li>
                        <li>lam.bingkong (Instagram)</li>
                        <li>lam.meilai (Instagram)</li>
                        <li>lin.chau.98 (Instagram)</li>
                        <li>mingchan1023 (Instagram)</li>
                        <li>nike_volunteer (Instagram)</li>
                        <li>nilnil_hk (Instagram)</li>
                        <li>niuhongfeng (Instagram)</li>
                        <li>ow_traveler (Instagram)</li>
                        <li>pisces.r_ (Instagram)</li>
                        <li>prettygirldraw (Instagram)</li>
                        <li>ray121sai (Instagram)</li>
                        <li>si_puith (Instagram)</li>
                        <li>sweetygingergleble (Instagram)</li>
                        <li>thefancyvalentina (Instagram)</li>
                        <li>vicky_in_her_wonderland (Instagram)</li>
                        <li>wendy_ghw (Instagram)</li>
                        <li>wowkq02 (Instagram)</li>
                        <li>yammyme (Instagram)</li>
                        <li>ymw.life (Instagram)</li>
                        <li>yuen5953 (Instagram)</li>
                        </ul>
                    </div>
                </section>
                <section className="mt-lg">
                    <div className="inner">
                        
                        <h2 className="gold-box text-md leading-tight font-bold mb-md">
                            <Trans i18nKey='end.top' components={{br: <br />}}/>
                        </h2>
                        <table class="result-table">
                            <tbody>
                                <tr>
                                    <td className="font-bold">{t('end.name')}</td>
                                    <td className="font-bold">{t('end.email')}</td>
                                </tr>
                                <tr>
                                    <td><span>Wong W** M** W**</span></td>
                                    <td><span>wm****w@gmail.com</span></td>
                                </tr>
                                <tr>
                                    <td><span>Kee C** L**</span></td>
                                    <td><span>ki****1@gmail.com</span></td>
                                </tr>
                                <tr>
                                    <td><span>Mrs Y**</span></td>
                                    <td><span>cc****g@gmail.com</span></td>
                                </tr>
                                <tr>
                                    <td><span>Man M**</span></td>
                                    <td><span>mi****g@gmail.com</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
                <div className="mt-md px-md">
                    <div className="my-lg">
                        <p className="text-sm text-center">
                            <Trans i18nKey="end.thankyou" components={{br: <br />}}/>
                        </p>
                    </div>
                    <p className="text-xs text-left">
                        <Trans i18nKey="home.promotion_license" components={{br: <br />}}/>
                    </p>
                </div>
            </div>

        </div>
    );
                
};


export default Announcement;