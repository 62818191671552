const EN = () => {
    return (
        <>
            <h1>
                My HKG – Top Spender Rewards (“Promotion”) Terms and Conditions
            </h1>

            <p>
                This promotion is held from 10 September 2024 to 13 October
                2024, both days inclusive <strong>(“Promotion Period”)</strong>.
            </p>

            <h3>Top Spender Rewards</h3>
            <ol>
                <li>
                    <p>
                        During the Promotion Period, 4 winners will be selected
                        based on the highest total electronic spending
                        accumulated through using the My HKG app’s airport
                        services booking function under the same e-mail account
                        (“Eligible Winners”). Each of the Eligible Winners will
                        receive ONE Top Spender Rewards package as detailed
                        below.
                    </p>
                    <p>
                        Each Top Spender Rewards package consists of the
                        following Prizes:
                    </p>

                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Prize details</th>
                                    <th>Supplier</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        ALLWAYS Meet & Assist service for two
                                        passengers
                                    </td>
                                    <td>ALLWAYS </td>
                                </tr>
                                <tr>
                                    <td>
                                        Regal Airport Hotel flight simulator
                                        experience{" "}
                                    </td>
                                    <td>Regal Airport Hotel</td>
                                </tr>
                                <tr>
                                    <td>
                                        Regal Airport Hotel OM SPA aromatherapy
                                        massage (60 mins){" "}
                                    </td>
                                    <td>Regal Airport Hotel</td>
                                </tr>
                                <tr>
                                    <td>
                                        ALLWAYS buggy service and INTERVALS Sky
                                        Bar & Restaurant’s signature
                                        cocktail/mocktail for two passengers{" "}
                                    </td>
                                    <td>ALLWAYS </td>
                                </tr>
                                <tr>
                                    <td>
                                        HKIA e-Coupons at a value of HK$500{" "}
                                    </td>
                                    <td>Airport Authority</td>
                                </tr>
                                <tr>
                                    <td>
                                        SF Express Home Baggage Check-in service
                                        for two pieces of luggage{" "}
                                    </td>
                                    <td>SF Express </td>
                                </tr>
                                <tr>
                                    <td>
                                        WiFiBB global 4G roaming pocket Wi-Fi
                                        with 30GB data worldwide{" "}
                                    </td>
                                    <td>WiFiBB</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <ol>
                        <li>
                            Eligible Spending means the final payment amount
                            settled by electronic payment after any relevant
                            product discounts, storewide discounts and Airport
                            e-Coupons/HKIA Dollars are applied.
                        </li>
                        <li>
                            Delivery fees are excluded from Eligible Spending.
                        </li>
                        <li>
                            In the event of any refund, the final Eligible
                            Spending will be spending after deducting the
                            refund.
                        </li>
                        <li>
                            Any person(s) under the age of 18 years old on the
                            first day of the Promotion Period, are not eligible
                            and are not permitted to participate in the
                            Promotion.
                        </li>
                    </ol>
                </li>
            </ol>

            <h3>Prizes Redemption</h3>

            <ol start="2">
                <li>
                    Only the Eligible Winners will receive an online form via
                    email within 10 days after the winner announcement on 22
                    October 2024.{" "}
                </li>
                <li>
                    Eligible Winners will be asked to provide their names and
                    email addresses for prize redemption arrangement. The{" "}
                    <strong>“Personal Information Collection Statement”</strong>{" "}
                    set out below applies. If an Eligible Winner fails to
                    respond within the deadline as stated in the email after
                    being notified, or if any incorrect or incomplete personal
                    information is provided, they will be considered to have
                    conceded or surrendered their prize, and no prize will be
                    reissued to them.
                </li>
                <li>
                    Each Eligible Winner will receive a unique redemption code
                    via the email provided in the online form 
                    <strong>(“Redemption Email”)</strong> by 19 November 2024.
                    Each of the Eligible Winners must claim the prize within 30
                    days after the date the Redemption Email is sent to them,
                    otherwise the prize will be considered forfeited. The
                    winning and redemption eligibility of any Eligible Winner
                    that fails to comply with paragraph 1.4 above will be void.
                    In addition, access to and/or use of any products or
                    services in the Top Spender Rewards package that are legally
                    prohibited to be sold or supplied to persons under 18 years
                    of age may be subject to the relevant satisfactory provision
                    to the relevant supplier an official form of identity proof
                    to verify the age eligibility of an Eligible Winner.{" "}
                </li>
                <li>
                    By using the Redemption Code, each Eligible Winner fully and
                    unconditionally agrees to and accepts these Terms &
                    Conditions.
                </li>
                <li>
                    Each Redemption Code is only valid for the Eligible Winner
                    to whom it is intended to be sent and for one-time usage
                    only.
                </li>
                <li>
                    The prize redemption will be conducted in an electronic
                    format, in the form of QR codes, coupon codes, e-vouchers
                    and/or e-coupons. The QR codes, coupon codes, e-vouchers
                    and/or e-coupons of the prizes for each Eligible Winner are
                    unique and the winners are solely responsible for keeping
                    them safe and secure. If any QR code, coupon code, e-voucher
                    and/or e-coupon is found to have been used when it is
                    presented for using the offer, the Airport Authority will
                    not provide a new QR code, coupon code, e-voucher and/or
                    e-coupon as replacement.{" "}
                </li>
                <li>
                    The Eligible Winners represent and warrant that the personal
                    data which he/she provides to the Airport Authority during
                    the Promotion Period is true, complete and accurate. The
                    Airport Authority shall not be held liable and shall be
                    fully indemnified by the Eligible Winner(s) for any
                    incorrect, incomplete and inaccurate personal data provided.
                </li>
                <li>
                    The Airport Authority reserves the right of final decision
                    should a dispute arise in relation to the redemption of the
                    prizes under this Promotion.
                </li>
                <li>All the prizes are sponsored by Airport Authority.</li>
                <li>
                    Prizes are subject to the product or service terms and
                    conditions of the relevant prize supplier(s) and the
                    relevant prize redemption periods. Please contact the prize
                    suppliers for details.
                </li>
                <li>
                    The Airport Authority reserves the right to change, suspend
                    or terminate the Promotion at any time without prior notice.
                </li>
                <li>
                    In case of any dispute arising from or in connection with
                    this Promotion, the decision of the Airport Authority shall
                    be final.
                </li>
                <li>
                    In case of any discrepancy between the Chinese and English
                    versions of these Terms and Conditions, the English version
                    shall prevail for all purposes.
                </li>
            </ol>

            <h3>Personal Information Collection Statement </h3>
            <ol>
                <li>
                    Winners will be asked to provide the following information:
                    <ol>
                        <li>
                            The winner’s full name (as shown on a valid proof of
                            identity);
                        </li>
                        <li>Email address</li>
                        <li>Mobile number </li>
                        <li>
                            Winner’s date of birth to verify the winner is 18
                            years of age or above. If the winners are under 18
                            years old, their prize winning and redemption
                            eligibility will be cancelled; and
                        </li>
                        <li>
                            such other data provided to the Airport Authority
                            through the “My HKG” app’s airport services booking
                            function for the purpose of facilitating this
                            Promotion and winners’ prize redemption{" "}
                        </li>
                    </ol>
                </li>
                <li>
                    The supply of the data is obligatory. If you do not provide
                    the data or the data provided is insufficient, the winning
                    eligibility will be cancelled and you may not be able to
                    redeem the Top Spender Rewards package.
                </li>
                <li>
                    Personal data collected from the Eligible Winners will be
                    used by the Airport Authority for the following purposes:
                    <ol>
                        <li>
                            Verifying the Eligible Winners’ eligibility to
                            participate and win in the campaign;
                        </li>
                        <li>Facilitating future audit of the campaign;</li>
                        <li>
                            Notifying the Eligible Winners they are selected as
                            winners and the relevant prize redemption
                            arrangement; and
                        </li>
                        <li>
                            Any other purposes incidental to the purposes stated
                            above.
                        </li>
                    </ol>
                </li>
                <li>
                    Personal data of the Eligible Winners submitted to the
                    Airport Authority will be kept confidential and will not be
                    disclosed to any third party except that it may be
                    transferred to: <br />
                    (a) Suppliers of the Top Spender Rewards package including
                    ALLWAYS, Regal Airport Hotel, Airport Authority and WiFiBB
                    for verifying the Eligible Winner’s eligibility and (b) any
                    person or company (including third party agent or
                    sub-contractor) (“Third Party Service Providers” who are
                    acting for or is engaged by the Airport Authority in
                    implementing the Promotion, including communicating with the
                    winners in relation to the Promotion, facilitating the
                    selection and notification of prize winners and the relevant
                    prize redemption arrangement;{" "}
                </li>
                <li>
                    Our general data retention policy is set out under “How long
                    do we retain your personal data?” section of the General
                    Terms of our Privacy Policy referred to below. Personal data
                    provided to the Airport Authority as specified above will be
                    retained by the Airport Authority for 3 months, after which
                    it will be destroyed.{" "}
                </li>
                <li>
                    Participants are advised to refer to the Airport Authority’s
                    Privacy Policy at (www.hkairportbooking.com/en/pics-english)
                    for more details. Should Participants have any enquiries,
                    please send an email to MYHKGPROMO@hkairport.com.
                </li>
                <li>
                    The Eligible Winners can request access to and correction of
                    the data provided by them, please contact our General
                    Personal Data Officer via the address below:{" "}
                </li>
            </ol>

            <p>General Personal Data Officer</p>
            <p>Airport Authority</p>
            <p>HKIA Tower</p>
            <p>1 Sky Plaza Road</p>
            <p>Hong Kong International Airport</p>
            <p>Lantau Island</p>
            <p>Hong Kong</p>
        </>
    );
};

const TC = () => {
    return (
        <>
            <h1>My HKG – 最高消費獎賞（「促銷」）條款及細則</h1>
            <p>
                本促銷活動將於 2024 年 9 月 10 日至 2024 年 10 月 13
                日期間舉行，首尾兩天包括在內（「促銷期間」）。
            </p>
            <h3>最高消費獎賞</h3>
            <ol>
                <li>
                    促銷期間，我們將根據以同一個電郵帳戶使用 My HKG
                    應用程式的機場服務預訂功能累計電子消費總額最高者，選出四位得獎者（「合資格得獎者」）。每位「合資格得獎者」將獲得「一份」最高消費獎賞組合，詳情如下。
                    <br />
                    每位最高消費獎賞組合均包括以下獎品：
                    <br />
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>獎品詳情</th>
                                    <th>供應商</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        為兩名乘客提供「ALLWAYS 接送機服務」{" "}
                                    </td>
                                    <td>ALLWAYS </td>
                                </tr>
                                <tr>
                                    <td>富豪機場酒店飛行模擬器體驗 </td>
                                    <td>富豪機場酒店</td>
                                </tr>
                                <tr>
                                    <td>
                                        富豪機場酒店 OM SPA 香薰按摩水療體驗（60
                                        分鐘）
                                    </td>
                                    <td>富豪機場酒店 </td>
                                </tr>
                                <tr>
                                    <td>
                                        ALLWAYS 電動車接送服務，以及
                                        INTERVALS天際酒吧及餐廳的雞尾酒/無酒精雞尾酒，供兩位乘客享用
                                    </td>
                                    <td>ALLWAYS </td>
                                </tr>
                                <tr>
                                    <td>價值500港元香港國際機場電子現金券 </td>
                                    <td>機場管理局</td>
                                </tr>
                                <tr>
                                    <td>
                                        順豐速運上門行李託運服務，可託運兩件行李
                                    </td>
                                    <td>順豐速運 </td>
                                </tr>
                                <tr>
                                    <td>
                                        Wi-FiBB 全球 4G 漫遊Pocket Wi-Fi，全球
                                        30 GB 數據
                                    </td>
                                    <td>WiFiBB</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    合資格消費指利用任何相關產品折扣、全店折扣及機場電子現金券／HKIA
                    Ｄollars後，以電子支付方式結算的最終付款金額。
                    <ol>
                        <li>送遞費用不計入合資格消費內。</li>
                        <li>
                            如有任何退款，則最終合資格消費將為扣除退款後的消費金額。
                        </li>
                        <li>
                            凡於促銷期間首天未滿18歲的任何人士均不符合資格亦不得參加促銷。
                        </li>
                    </ol>
                </li>
            </ol>
            <h3>獎品兌換</h3>
            <ol>
                <li>
                    只有「合資格得獎者」方會於 2024 年 10 月 22
                    日公布得獎者後的10天內透過電子郵件收到網上表格。
                </li>
                <li>
                    「合資格得獎者」必須提供姓名及電郵地址，以便安排獎品兌換。下文載列的《收集個人資料聲明》適用。如得獎者在收到通知後未能在電子郵件所列期限內回覆，或提供任何不正確或不完整的個人資料，則被視為讓出或放棄獎品論，並不會重新獲發獎品。
                </li>
                <li>
                    每位合資格得獎者將於2024年11月19日之前透過網上表格所提供的電子郵件（「兌換電子郵件」）收到獨一無二的兌換代碼。每位「合資格得獎者」必須在向他們發出兌換電子郵件日期起計30天內兌換獎品，否則獎品將視作放棄論。如任何合資格得獎者未能遵守上文第1.4條規定，則其得獎及兌換資格將無效。此外，取得及／或使用最高消費獎賞組合（其中包含依法禁止向
                    18歲以下人士出售或提供的任何產品或服務），可能須向相關供應商提供符合正式規格且令人滿意的身分證明文件，以核實合資格得獎者年齡是否符合資格。
                </li>
                <li>
                    透過使用「兌換代碼」，即表示每位合資格得獎者完全無條件地同意並接受本條款及細則。
                </li>
                <li>
                    每個兌換代碼僅供擬收取的「合資格得獎者」使用方為有效，且僅可使用一次。
                </li>
                <li>
                    獎品兌換將以電子方式進行，包括二維碼、優惠券代碼、電子禮券及／或電子現金券的形式。每個符合資格的得獎者的獎品二維碼、優惠券代碼、電子禮券及／或電子現金券都是獨一無二的，得獎者應全權負責以確保其妥為保管。如在出示以使用時發現任何二維碼、優惠券代碼、電子禮券及/或電子現金券已被使用，機場管理局將不會提供新的二維碼、優惠券代碼、電子禮券及/或電子現金券作為替代品。
                </li>
                <li>
                    「合資格得獎者」聲明並保證，在促銷期間其向機場管理局提供的個人資料屬真實、完整及準確。對於所提供任何不正確、不完整及不準確的個人資料，機場管理局概不承擔任何責任，並應由合資格得獎者作出全面彌償。
                </li>
                <li>
                    如因本促銷活動獎品兌換事宜產生任何爭議，則機場管理局保留最終決定權。
                </li>
                <li>所有獎品由機場管理局贊助。</li>
                <li>
                    獎品受相關獎品供應商的產品或服務條款及細則，以及相關獎品兌換期約束。有關詳情請聯絡獎品供應商。
                </li>
                <li>
                    機場管理局保留權利得隨時更改、暫停或終止促銷活動，恕不作事前通知。
                </li>
                <li>
                    如因本促銷活動引起或與之相關的任何爭議，則機場管理局保留最終決定權。
                </li>
                <li>
                    如本條款及細則的中文及英文版本有任何不一致之處，在任何情況下均以英文版本為準。
                </li>
            </ol>
            <h3>《收集個人資料聲明》</h3>
            <ol>
                <li>
                    得獎者必須提供下列資料：
                    <ol>
                        <li>得獎者全名（如有效身分證明文件上所示）；</li>
                        <li>電郵地址 </li>
                        <li>手提電話號碼</li>
                        <li>
                            得獎者的出生日期，以核實得獎者是否年滿18歲。如得獎者未滿18歲，則其得獎及兌換獎品資格將被取消；及{" "}
                        </li>
                        <li>
                            透過「我的
                            HKG」應用程式的機場服務預訂功能向機場管理局提供的該等其他資料，以助進行本促銷活動及協助得獎者兌換獎品。
                        </li>
                    </ol>
                </li>
                <li>
                    得獎者必須提供資料。如你不提供資料或所提供的資料不足夠，則其得獎資格將被取消，且你可能無法兌換最高消費獎賞組合。
                </li>

                <li>
                    機場管理局會將所收集「合資格得獎者」的個人資料用作下列用途：
                    <ol>
                        <li>核實「合資格得獎者」參加及於活動中勝出的資格；</li>
                        <li>以助日後對該活動進行審核；</li>
                        <li>
                            通知「合資格得獎者」他們被選為得獎者，以及作相關獎品兌換安排；及
                        </li>
                        <li>上述目的附帶的任何其他目的。 </li>
                    </ol>
                </li>
                <li>
                    「合資格得獎者」提交予機場管理局的個人資料將會保密，並不會向任何第三方披露，惟該等資料可能會轉移至：
                    <ol>
                        <li>
                            最高消費獎賞組合的供應商，包括
                            ALLWAYS、富豪機場酒店、機場管理局及WiFiBB，以核實合資格得獎者資格，及
                        </li>
                        <li>
                            代表機場管理局或獲機場管理局委託進行促銷活動的任何人士或公司（包括第三方代理或承辦商）（「第三方服務供應商」），包括與得獎者就促銷活動進行溝通、協助選擇及通知得獎者，以及作相關獎品兌換安排。
                        </li>
                    </ol>
                </li>
                <li>
                    我們的一般資料保留政策已載於我們的《私隱政策》一般條款部分有關「我們會保留你的個人資料多久？」一節。機場管理局將參與者提供的個人資料保留三個月後銷毀。
                </li>
                <li>
                    請參與者參閱機場管理局的《私隱政策》(www.hongkongairport.com/tc/privacy-policy.page)
                    以了解更多詳情。如參與者有任何疑問，請發送電郵至MYHKGPROMO@hkairport.com。
                </li>

                <li>
                    「合資格得獎者」可以要求存取及更正他們提供的資料，請透過以下地址聯絡我們的一般個人資料主任：<br />
                    香港大嶼山
                    <br />
                    香港國際機場
                    <br />
                    翔天路 1 號<br />
                    機場行政大樓
                    <br />
                    機場管理局
                    <br />
                    一般個人資料主任
                </li>
            </ol>
        </>
    );
};
const SC = () => {
    return <>
        <h1>My HKG – 最高消费奖赏（「促销」）条款及细则</h1>
        <p>本促销活动将于2024年9月10日至2024年10月13日期间举行，首尾两天包括在内（「促销期间」）。</p>
        <h3>最高消费奖赏</h3>
        <ol>
            <li>促销期间，我们将根据以同一个电邮帐户使用My HKG应用程式的机场服务预订功能累计电子消费总额最高者，选出四位得奖者（「合资格得奖者」）。每位「合资格得奖者」将获得「一份」最高消费奖赏组合，详情如下。
                <br />
                每位最高消费奖赏组合均包括以下奖品：
                <br />
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>奖品详情</th>
                                <th>供应商</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr><td>为两名乘客提供「ALLWAYS 接送机服务」	</td><td>ALLWAYS </td></tr>
                        <tr><td>富豪机场酒店飞行模拟器体验  	</td><td>富豪机场酒店</td></tr>
                        <tr><td>富豪机场酒店OM SPA 香薰按摩水疗体验（ 60分钟）	</td><td>富豪机场酒店</td></tr>
                        <tr><td>ALLWAYS 电动车接送服务，以及INTERVALS天际酒吧及餐厅的鸡尾酒/没有酒精鸡尾酒，供两位乘客享用 	</td><td>ALLWAYS </td></tr>
                        <tr><td>价值500港元香港国际机场电子现金券 	</td><td>机场管理局</td></tr>
                        <tr><td>顺丰速运上门行李托运服务，可托运两件行李	</td><td>顺丰速运  </td></tr>
                        <tr><td>Wi-FiBB 全球 4G 漫游Pocket Wi-Fi，全球 30 GB数据	</td><td>WiFiBB</td></tr>
                        </tbody>
                    </table>
                </div>
            </li>
            <li>
                合资格消费指利用任何相关产品折扣、全店折扣及机场电子现金券/HKIA 
                Dollars后，以电子支付方式结算的最终付款金额。
                <ol>
                    <li>送递费用不计入合资格消费内。</li>
                    <li>
                    如有任何退款，则最终合资格消费将为扣除退款后的消费金额。
                    </li>
                    <li>
                    凡於促销期间首天未满18岁的任何人士均不符合资格亦不得参加促销。
                    </li>
                </ol>
            </li>
        </ol>
        <h3>奖品兑换</h3>
        <ol>
        <li>只有「合资格得奖者」方会於2024年10月22日公布得奖者后的10天内通过电子邮件收到网上表格。</li>
        <li>「合资格得奖者」必须提供姓名及电邮地址，以便安排奖品兑换。下文载列的《收集个人资料声明》适用。如得奖者在收到通知后未能在电子邮件所列期限内回复，或提供任何不正确或不完整的个人资料，则被视为让出或放弃奖品论，并不会重新获发奖品。</li>
        <li>每位合资格得奖者将于2024年11月19日之前通过网上表格所提供的电子邮件（「兑换电子邮件」）收到独一无二的兑换代码。每位「合资格得奖者」必须在向他们发出兑换电子邮件日期起计30天内兑换奖品，否则奖品将视作放弃论。如任何合资格得奖者未能遵守上文第1.4条规定，则其得奖及兑换资格将无效。此外，取得及/或使用最高消费奖赏组合（其中包含依法禁止向18岁以下人士出售或提供的任何产品或服务），可能须向相关供应商提供符合正式规格且令人满意的身分证明文件，以核实合资格得奖者年龄是否符合资格。</li>
        <li>通过使用「兑换代码」，即表示每位合资格得奖者完全无条件地同意并接受本条款及细则。</li>
        <li>每个兑换代码仅供拟收取的「合资格得奖者」使用方为有效，且仅可使用一次。</li>
        <li>奖品兑换将以电子方式进行，包括二维码、优惠券代码、电子礼券及/或电子现金券的形式。每个符合资格的得奖者的奖品二维码、优惠券代码、电子礼券及/或电子现金券都是独一无二的，得奖者应全权负责以确保其妥为保管。如在出示以使用时发现任何二维码、优惠券代码、电子礼券及/或电子现金券已被使用，机场管理局将不会提供新的二维码、优惠券代码、电子礼券及/或电子现金券作为替代品。</li>
        <li>「合资格得奖者」声明并保证，在促销期间其向机场管理局提供的个人资料属真实、完整及准确。对于所提供任何不正确、不完整及不准确的个人资料，机场管理局概不承担任何责任，并应由合资格得奖者作出全面弥偿。</li>
        <li>如因本促销活动奖品兑换事宜产生任何争议，则机场管理局保留最终决定权。</li>
        <li>所有奖品由机场管理局赞助。</li>
        <li>奖品受相关奖品供应商的产品或服务条款及细则，以及相关奖品兑换期约束。有关详情请联络奖品供应商。</li>
        <li>机场管理局能保留权利随时更改、暂停或终止促销活动，恕不作事前通知。</li>
        <li>如因本促销活动引起或与之相关的任何争议，则机场管理局保留最终决定权。</li>
        <li>如本条款及细则的中文及英文版本有任何不一致之处，在任何情况下均以英文版本为准。</li>
        </ol>
        <h3>《收集个人资料声明》</h3>
        <ol>
            <li>
            得奖者必须提供下列资料：
                <ol>
                    <li>得奖者全名（如有效身分证明文件上所示）; </li>
                    <li>电邮地址 </li>
                    <li>手提电话号码 </li>
                    <li>
                    得奖者的出生日期，以核实得奖者是否年满18岁。如得奖者未满18岁，则其得奖及兑换奖品资格将被取消;及
                    </li>
                    <li>透过「我的HKG」应用程式的机场服务预订功能向机场管理局提供的该等其他资料，以助进行本促销活动及协助得奖者兑换奖品。</li>
                </ol>
            </li>
            <li>得奖者必须提供资料。如你不提供资料或所提供的资料不足够，则其得奖资格将被取消，且你可能无法兑换最高消费奖赏组合。</li>

            <li>
            机场管理局会将所收集「合资格得奖者」的个人资料用作下列用途：
                <ol>
                <li>核实「合资格得奖者」参加及于活动中胜出的资格;</li>
                <li>以助日后对该活动进行审核;</li>
                <li>通知「合资格得奖者」他们被选为得奖者，以及作相关奖品兑换安排;及</li>
                <li>上述目的附带的任何其他目的。</li>
                </ol>
            </li>
            <li>
            「合资格得奖者」提交予机场管理局的个人资料将会保密，并不会向任何第三方披露，惟该等资料可能会转移至：
                <ol>
                    <li>最高消费奖赏组合的供应商，包括ALLWAYS、富豪机场酒店、机场管理局及WiFiBB，以核实合资格得奖者资格，及</li>
                    <li>代表机场管理局或获机场管理局委托进行促销活动的任何人士或公司（包括第三方代理或承办商）（「第三方服务供应商」），包括与得奖者就促销活动进行沟通、协助选择及通知得奖者，以及作相关奖品兑换安排。</li>
                </ol>
            </li>
            <li>
            我们的一般资料保留政策已载于我们的《私隐政策》一般条款部分有关「我们会保留你的个人资料多久？」一节。机场管理局将参与者提供的个人资料保留三个月后销毁。
            </li>
            <li>
            请参与者参阅机场管理局的《隐私政策》（www.hongkongairport.com/sc/privacy-policy.page）以了解更多详情。如参与者有任何疑问，请发送电邮至 MYHKGPROMO@hkairport.com。
            </li>

            <li>
                「合资格得奖者」可以要求存取及更正他们提供的资料，请透过以下地址联络我们的一般个人资料主任：<br />
                香港大屿山<br />
                香港国际机场<br />
                翔天路 1 号<br />
                机场行政大楼<br />
                机场管理局<br />
                一般个人资料主任

            </li>
        </ol>
    </>;
};

export { EN, TC, SC };
