const triggerGTMEvent = (eventName, eventParams = {}) => {
    if (window.gtag) {
        window.gtag('event', eventName, eventParams);
    } else {
        window.dataLayer = window.dataLayer || [];
        const event = {
          event: eventName,
          event_name: eventName,
          ...eventParams
        };
        // console.warn('gtag is not defined. Use dataLayer.push instead.');
        // console.log('event', event);
        window.dataLayer.push(event);
    }
};

export {triggerGTMEvent};