import React, { useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { AppContext } from "../context/AppContext.js";
import { useNavigate, useLocation } from 'react-router-dom';

const LanguageSwitcher = () => {

    const { dispatch } = useContext(AppContext);
    const { triggerGTMEvent } = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();


    // switch language using reacti18next
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage( lng );
        navigate(`/?lang=${i18n.language}`);
        triggerGTMEvent('change-language', {
            lang: lng
        })
        dispatch({ type: 'SET_LOADING', payload: true });
        setTimeout(() => {
            dispatch({ type: 'SET_LOADING', payload: false });
        },500);
    }

    useEffect(() => {
        document.title = t('title');

        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', t('home.intro'));
        }

        const ogTitle = document.querySelector('meta[property="og:title"]');
        if (ogTitle) {
            ogTitle.setAttribute('content', t('title'));
        }

        const ogDescription = document.querySelector('meta[property="og:description"]');
        if (ogDescription) {
            ogDescription.setAttribute('content', t('home.intro'));
        }
    }, [i18n.language]);

    return (
        <>
            <div className="text-[14px] flex gap-[4px]">
                <button className={ i18n.language === 'en'?'underline': '' } onClick={()=>{changeLanguage('en')}}>EN</button>
                <span>/</span> 
                <button className={ i18n.language === 'tc'?'underline': '' } onClick={()=>{changeLanguage('tc')}}>繁</button>
                <span>/</span>
                <button className={ i18n.language === 'sc'?'underline': '' } onClick={()=>{changeLanguage('sc')}}>簡</button>
            </div>
        </>
    )
}

export default LanguageSwitcher;