// src/context/QuizContext.js
import React, { createContext, useReducer } from 'react';
import {triggerGTMEvent} from '../Tracking.js';

const initialState = {
  isLoading: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload
      };
    default:
      return state;
  }
};

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  
  return (
    <AppContext.Provider value={{ state, dispatch, triggerGTMEvent }}>
      {children}
    </AppContext.Provider>
  );
};
