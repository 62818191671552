// src/context/QuizContext.js
import React, { createContext, useReducer } from 'react';

const types = ['efficiency', 'shopaholic', 'business', 'foodie', 'pamperer']; //please match with the translation json

const initialState = {
  currentQuestion: 0,
  scores: [0, 0, 0, 0, 0], // Assuming 5 different results
  resultIndex: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ANSWER_QUESTION':
      const newScores = state.scores.map((score, index) => score + action.payload.scores[index]);
      const getResultId = function (){
        const idx = getHighestIndex();
        // console.log('getResultId__idx: ', idx);
        return types[idx];
      };
      const getHighestIndex = function (){
        // console.log('getHighestIndex__newScores: ', newScores);
        let highestIndex = 0;
        let highestScore = newScores[0];
        for (let i = 0; i < newScores.length; i++) {
          if(newScores[i] === highestScore){
            if(i === 1 || i === 3){
              highestIndex = i;
              continue;
            }
          }
          if (newScores[i] > highestScore) {
            highestScore = newScores[i];
            highestIndex = i;
          }
        }
        // console.log('getHighestIndex__highestIndex: ', highestIndex);
        return highestIndex;
      }
      return {
        ...state,
        scores: newScores,
        currentQuestion: state.currentQuestion < 9 ? state.currentQuestion + 1 : state.currentQuestion,
        resultIndex: getHighestIndex(),
        resultId: getResultId()
      };
    case 'RESET_QUIZ':
      return initialState;
    default:
      return state;
  }
};

export const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <QuizContext.Provider value={{ state, dispatch }}>
      {children}
    </QuizContext.Provider>
  );
};
