import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import "./styles/common.scss";
import { useTranslation } from "react-i18next";
import { QuizProvider } from "./context/QuizContext.js";
import { AppProvider, AppContext } from "./context/AppContext.js";
import Home from "./pages/Home.js";
import Quiz from "./pages/Quiz.js";
import Result from "./pages/Result.js";
import Test from "./pages/Test.js";
import { DisplayState, LoadingScreen } from "./components/Misc.js";
import { triggerGTMEvent } from './Tracking.js';
import Announcement from "./pages/Announcement.js";

function App() {
    const { t, i18n } = useTranslation();
    const [currentPage, setCurrentPage] = useState("home");

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const lang = queryParams.get('lang');

        if (lang) {
            i18n.changeLanguage(lang);
            triggerGTMEvent('landing-language', {
                lang: lang
            })
            return;
        }
        triggerGTMEvent('landing-language', {
            lang: 'default:tc'
        })
    },[]);

    useEffect(() => {
        setRemSize(i18n.language);
    }, [i18n.language]);

    const setRemSize = (language) => {
        const root = document.documentElement;
        if (language === 'en') {
          root.style.setProperty('--rem-size', '14px');
          root.style.setProperty('font-size', '14px');
        } else {
          root.style.setProperty('--rem-size', '16px');
          root.style.setProperty('font-size', '16px');
        }
    };

    const changePage = (page) => {
        setCurrentPage(page);
    };

    const renderPage = () => {

        // show announcemnt page if date is greater than 2024-10-10
        const today = new Date();
        const announcementDate = new Date('2024-10-10');
        if(today > announcementDate) {
            return <Announcement />;
        }

        switch (currentPage) {
            case "home":
                return <Home onChangePage={changePage} />;
            case "quiz":
                return <Quiz onChangePage={changePage} />;
            case "result":
                return <Result onChangePage={changePage} />;
            default:
                return <Home onChangePage={changePage} />;
        }
    };

    return (
        <AppProvider>
            <div className="App">
                <div className="app-wrapper">
                    <QuizProvider>
                        <Router>
                            <Routes>
                                {/* <Route path="/result" element={<Result />} /> */}
                                {/* <Route path="/test" element={<Test />} /> */}
                                <Route path="/result" element={<Announcement />} />
                                <Route path="*" element={renderPage()} /> 
                            </Routes>
                        </Router>
                        {/* {renderPage()} */}

                        {/* <DisplayState /> */}
                        <LoadingScreen />
                    </QuizProvider>
                </div>
            </div>
        </AppProvider>
    );
}

export default App;
