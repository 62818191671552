import React, { useState, useContext } from "react";
import { useTranslation, Trans } from 'react-i18next';
import Kv from '../components/Kv';
import LanguageSwitcher from '../components/LanguageSwitcher';
// import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import {  EN, SC, TC  } from '../components/Tnc';
import {  EN as EN_PRIZE, TC as TC_PRIZE, SC as SC_PRIZE  } from '../components/Tnc_prize';
import { AppContext } from "../context/AppContext.js";
import { Link } from "react-router-dom";

const Home = ({onChangePage}) => {
    const { t, i18n } = useTranslation();
    const [ isShowingTnc, setIsShowingTnc ] = useState(false);
    const [ currentTNC, setCurrentTNC ] = useState();

    // const { triggerGTMEvent } = useContext(AppContext);

    const share = () => {
        // triggerGTMEvent('home_share');

        if (navigator.share) {
            navigator.share({
                url: t("app_url")
            })
            .then(() => console.log('Thanks for sharing!'))
            .catch(console.error);
        } else {
            console.error('Sharing is not supported on this browser.');
        }
    }

    const showTnc = (content) => {
        setCurrentTNC(content);
        setIsShowingTnc(true);
    }
    const hideTnc = () => {
        setCurrentTNC(null);
        setIsShowingTnc(false);
    }
    const GetTnc = () => {
        console.log('GetTnc: ', currentTNC);
        if(currentTNC === 'tnc') {
            switch(i18n.language) {
                case 'en':
                    return <EN />
                case 'tc':
                    return <TC />
                case 'sc':
                    return <SC />
                default:
                    return <EN />
            }
        }
        if(currentTNC === 'tnc_prize') {
            switch(i18n.language) {
                case 'en':
                    return <EN_PRIZE />
                case 'tc':
                    return <TC_PRIZE />
                case 'sc':
                    return <SC_PRIZE />
                default:
                    return <EN_PRIZE />
            }
        }
    }

    return (
        <div className='page page-home' style={{
            backgroundImage: 'url("/images/bg-home.jpg")'
        }}>

            {isShowingTnc && 
                <div className="popup" >
                    <div className="popup-overlay" onClick={()=>{hideTnc()}}></div>
                    <div className="popup-content">
                        <div className="p-md text-left plaintext">
                            <GetTnc />
                        </div>
                    </div>
                    <button className="popup-close" onClick={()=>{hideTnc()}}>{t('close')}</button>
                </div>
            }

            <div className='absolute top-sm right-md flex justify-end z-10' >
                <LanguageSwitcher />
            </div>
            <div className="pt-lg">
                <div className="kv-container">
                    <Kv />
                </div>
            </div>
            <div className="content-container">
                <div className="mb-md -mt-lg">
                    <span className='text-xxl shadowing'>{t('home.display1')}</span>
                    <br />
                    <span className="primary-block px-4 py-1 text-lg">{t('home.display2')}</span>
                </div>
                <div className="container my-md">
                    <p>{t('home.intro')}</p>
                    <div className="mt-md text-center">
                        <img src="/images/graphs/arrow-down.png" alt="" className="mx-auto w-[24px]"/>
                    </div>
                </div>
                <div className="gold-box text-md">{t('home.heading1')}</div>
                <div className="container">
                    <div className="my-md bg-primary text-white rounded-[30px] border border-white px-6 py-8">
                        <div className="flex justify-between text-center items-flex-start">
                            <span className="grow">
                                <span className='text-md'>{t("home.step1")}</span>
                                <div className="w-[80%] mx-auto my-sm"><img src="/images/home_01.png" alt="" /></div>
                                {t("home.task1")}
                            </span>
                            <span className="self-center">
                                <img className="w-[80%] mx-auto" src="/images/home_02.png" alt="" />
                            </span>
                            <span className="grow">
                                <span className='text-md'>{t("home.step2")}</span>
                                <div className="w-[80%] mx-auto my-sm"><img src="/images/home_03.png" alt="" /></div>
                                {t("home.task2")}
                            </span>
                        </div>  
                    </div>
                    <div className="my-md">
                        <h2 className='text-md'>{t("home.instruction_facebook_title")}</h2>
                        {/* <p>{t("home.instruction_facebook")}</p> */}
                        <Trans i18nKey="home.instruction_facebook" components={{span: <span />}}/>
                    </div>
                    <div className="my-md">
                        <h2 className='text-md'>{t("home.instruction_instagram_title")}</h2>
                        {/* <p>{t("home.instruction_instagram")}</p> */}
                        <Trans i18nKey="home.instruction_instagram" components={{span: <span />}}/>
                    </div>
                    <div className="mb-md mt-lg">
                        <button className="gtm-home-tnc underline text-xs" onClick={()=>{
                            showTnc('tnc');
                        }}>{t("home.tnc")}</button>
                        <br />
                        <button className="gtm-home-tnc underline text-xs" onClick={()=>{
                            showTnc('tnc_prize');
                        }}>{t("home.tnc_prize")}</button>
                    </div>
                    <div className="my-md">
                        <button className="gtm-home-start block w-full gold-box h-[40px] rounded-[20px]" onClick={()=>{
                            onChangePage('quiz')
                        }}>
                            {/* <Link to="/quiz">{t("home.start")}</Link> */}
                            <span>{t("home.start")}</span>
                        </button>
                    </div>
                    {/* <div className="">
                        <div className="flex items-center justify-center">
                            <button onClick={share} className="gtm-home-share">
                                <img src="/images/graphs/share.png" alt="" className="w-[24px] inline mr-xs"/>
                                {t("home.share")}
                            </button>
                        </div>
                    </div> */}
                    <div className="mt-lg pb-md">
                        <p className="text-xs text-left text-white">
                        <Trans i18nKey="home.promotion_license" components={{br: <br />}}/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;