const ImagesList = [
    "images/kv_prize_2.png",
    "images/bg-home.jpg",
    "images/bg-long.jpg",
    "images/bg-question.jpg",
    "images/deco.png",
    "images/home_01.png",
    "images/home_02.png",
    "images/home_03.png",
    "images/kv_coins_1.png",
    "images/kv_coins_2.png",
    "images/kv_coins.png",
    "images/kv_flight.png",
    "images/kv_phone_en.png",
    "images/kv_phone_tc.png",
    "images/kv_phone_sc.png",
    "images/kv_prize_1.png",
    "images/kv_prize.png",
    "images/result-1.png",
    "images/result-2.png",
    "images/result-3.png",
    "images/result-4.png",
    "images/result-5.png",
    "images/result-icon_fb-gradient.png",
    "images/result-icon_friends.png",
    "images/result-icon_ig-gradient.png",
    "images/result-icon_share.png"
]

export default ImagesList;