const Progress = ({ currentQuestion }) => {
    return (
        <div className="progress-container py-lg">
            <div className={`progress-dots ${'dot-'+currentQuestion}`}>
                {/* loop through the number of questions - 10 questions */}
                {[...Array(10)].map((_, index) => (
                    <div key={index} className="item">
                        <div className={`progress-dot ${currentQuestion === index?'active': ''} ${currentQuestion >= index ? 'completed' : ''}`}>
                            <div className="progress-num">{index+1}</div>
                        </div>
                        <div className="progress-line"></div>
                    </div> 
                ))}
            </div>
        </div>
    )
}

export default Progress;