// src/components/Question.js
import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { AppContext } from "../context/AppContext.js";
import { QuizContext } from '../context/QuizContext';

const Question = ({ question, options, onAnswer }) => {

  const letters = ["A", "B", "C", "D", "E"];
  const [selected, setSelected] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { triggerGTMEvent } = useContext(AppContext);
  const { state, dispatch } = useContext(QuizContext);

  const handleSelect = (index, optionScore) => {
    if (isProcessing) return;

    triggerGTMEvent('answer', {
      answer: `${parseInt(state.currentQuestion) + 1}: `+ letters[index]
    })

    setSelected(true);
    setSelectedIndex(index);
    setIsProcessing(true);
    setTimeout(() => {
      onAnswer(optionScore);
      reset();
    },500);
  };

  const reset = () => {
    setSelected(false);
    setSelectedIndex(null);
    setIsProcessing(false);
  };

  return (
    <div className="question">
      <div className='question-area'>
        <div className="container">
          <div className="flex h-[160px] items-center">
            <h2 className="shadowing w-full text-xxl font-bold leading-[1.1em]">
              {/* {question} */}
              <Trans
                i18nKey={question}
                components={{ br: <br /> }}
              />
            </h2>
          </div>
        </div>
      </div>
      <div className="answers-area">
          <div className="container">
            {options.map((option, index) => (
              <div key={index} className="my-md">
                <div className={`flex text-lg px-md py-md answer-block block w-full ${selected && selectedIndex === index?'active': ''} ${selected && selectedIndex !== index?'muted': ''}`} onClick={() => handleSelect(index, option.score)}>
                  <span className="relative basis-[30px] text-xxl">{letters[index]+'.'}</span>
                  <span className="pl-sm relative grow text-center leading-[1.1em]">
                    <Trans
                      i18nKey={option.text}
                      components={{ br: <br /> }}
                    />
                    {/* {option.text} */}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
    </div>
  );
};

export default Question;
