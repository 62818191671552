const EN = () => {
    return (
        <>
            <h1>
                <span>
                    "My HKG - My Travel Personality Quiz" Promotion Campaign
                    (the "Campaign")
                </span>
                <span>
                    <br />
                </span>
                <span>Terms and Conditions</span>
            </h1>
            <h3>
                <span>
                    How to participate in "My HKG - My Travel Personality Quiz"
                    Promotion Campaign Lucky Draw (the "Lucky Draw"):
                </span>
            </h3>
            <ol>
                <li>
                    <p>
                        <span>
                            By participating in the Lucky Draw, each participant
                            confirms that he/she has read, understood and agreed
                            to be bound by these terms and conditions set out
                            herein, including any other requirements set out in
                            any related promotional material.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            The participation period starts from 10 September
                            2024 and last until 13 October 2024 (both dates
                            inclusive).
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            If there is any conflict between the information
                            contained in the promotional materials and these
                            terms and conditions, these terms and conditions
                            shall apply and prevail. In the event of
                            discrepancies in the terms and conditions between
                            the English and Chinese versions, the English
                            version shall prevail.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            In case of any dispute in respect of these terms and
                            conditions or any other matters arising out of the
                            Lucky Draw, the Authority reserves the right of
                            final decision. All decisions made by the Authority
                            regarding the interpretation and application of
                            these terms and conditions or on all matters
                            relating to the Lucky Draw shall be final and
                            conclusive in each case.
                        </span>
                    </p>
                </li>
            </ol>
            <h3>
                <span>Eligibility and method of participation:</span>
            </h3>
            <ol start="5">
                <li>
                    <p>
                        <span>
                            The participant must be 18 years of age or above
                            when he/she enters the Lucky Draw.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            To be eligible for the Lucky Draw, participants must
                            complete all three steps as stated below ("Eligible
                            Participant(s)"):
                        </span>
                    </p>
                    <p>
                        <span>
                            Step 1: Use the "My HKG" app to complete "My Travel
                            Personality Quiz".
                        </span>
                    </p>
                    <p>
                        <span>
                            Step 2: Share their travel personality quiz result
                            either with a public post on their own Facebook
                            account or a public story on their own Instagram
                            account.
                        </span>
                    </p>
                    <p>
                        <span>
                            Step 3: Tag two friends and add the hashtag
                            #mytravelpersonality in the Facebook post or
                            Instagram story. For Facebook users, they are
                            required to tag our official "Hong Kong
                            International Airport 香港國際機場" Facebook page.
                            For Instagram users, they are required to tag our
                            official Instagram account @hongkongairport.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            When posting on their own social media accounts,
                            participants agree not to post any content that is
                            libellous, harassing, abusive, obscene, vulgar,
                            sexually explicit, or inappropriate concerning race,
                            gender, sexuality, ethnicity, or other intrinsic
                            characteristics. Additionally, any content
                            containing false or misleading statements, as well
                            as content that speaks detrimentally of Hong Kong
                            International Airport without proper, fair or
                            reasonable basis or that may involve or potentially
                            involve unlawful acts, is strictly prohibited. The
                            Airport Authority (the "Authority") reserves the
                            right to screen out any content that violate the
                            aforementioned requirements.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            Only the winners will be notified individually via
                            inbox message after the winners are announced. The
                            winners will be asked to provide their names and
                            email addresses for prize redemption arrangement.
                            The "Personal Information Collection Statement and
                            Privacy Policy" set out below applies. If a winner
                            fails to respond within 48 hours after being
                            notified, or if any incorrect or incomplete personal
                            information is provided, it will be considered as
                            surrendering the prize, and the prize will not be
                            reissued to the winner.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            Only one Prize can be won per social media account
                            throughout the Campaign Period.
                        </span>
                    </p>
                </li>
            </ol>
            <h3>
                <span>Disqualification</span>
            </h3>
            <ol start="10">
                <li>
                    <p>
                        <span>
                            If you encounter any of the following situations,
                            the Authority reserves the sole and absolute right
                            to cancel or exclude your participation in this
                            Lucky Draw and/or disqualify you from winning any
                            prizes, without prior notice:i. Violation of these
                            terms and conditions; ii. Provision of incorrect or
                            incomplete personal information (if applicable);
                            iii. Provision of personal information during prize
                            collection that does not match the information
                            provided during participation (if applicable);iv.
                            Participation in this Lucky Draw through illegal or
                            dishonest means; or vi. The Authority deems it
                            necessary to cancel or exclude your eligibility
                            under reasonable circumstances.
                        </span>
                    </p>
                </li>
            </ol>
            <h3>
                <span>Prizes and prize collection procedures</span>
            </h3>
            <ol start="11">
                <li>
                    <p>
                        <span>
                            Five travel styles will be assessed in "My HKG"
                            app&rsquo;s travel personality quiz. Each
                            Participant who completes the quiz will get a result
                            suggesting which one of these five travel styles
                            aligns most closely with the Participant&rsquo;s
                            personal travel preferences. Under each of the five
                            travel styles, 100 winners and reserve winners will
                            be drawn randomly by a computer from amongst the
                            Participants that possess that particular travel
                            style.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            There are 500 prizes ("the Prizes") in total.
                            Details of the prizes are as follows:
                        </span>
                    </p>

                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Travel Styles</th>
                                    <th>Total Quantity　</th>
                                    <th>Prize details </th>
                                    <th>Supplier</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">
                                        "Race against time" type
                                    </td>
                                    <td rowSpan="2">100</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>
                                                Plaza Premium Lounge – Global
                                                Lounge Access for two visits
                                            </li>
                                            <li>
                                                SF Express Home Baggage Check-in
                                                service for two pieces of
                                                luggage
                                            </li>
                                        </ul>
                                    </td>
                                    <td>Plaza Premium Lounge</td>
                                </tr>
                                <tr>
                                    <td>SF Express</td>
                                </tr>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">
                                        "Shop til you drop" type
                                    </td>
                                    <td rowSpan="2">100</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>
                                                Plaza Premium Lounge – Global
                                                Lounge Access for two visits
                                            </li>
                                            <li>
                                                HKIA e-Coupons at a value of
                                                HK$500
                                            </li>
                                        </ul>
                                    </td>
                                    <td>Plaza Premium Lounge</td>
                                </tr>
                                <tr>
                                    <td>Airport Authority </td>
                                </tr>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">
                                        "Business as usual" type
                                    </td>
                                    <td rowSpan="2">100</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>
                                                Plaza Premium Lounge – Global
                                                Lounge Access for two visits
                                            </li>
                                            <li>
                                                WiFiBB global 4G roaming pocket
                                                Wi-Fi with 30GB data worldwide
                                            </li>
                                        </ul>
                                    </td>
                                    <td>Plaza Premium Lounge</td>
                                </tr>
                                <tr>
                                    <td>WiFiBB</td>
                                </tr>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">
                                        "Food before anything" type
                                    </td>
                                    <td rowSpan="2">100</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>
                                                Plaza Premium Lounge – Global
                                                Lounge Access for two visits
                                            </li>
                                            <li>
                                                ALLWAYS buggy service and
                                                INTERVALS Sky Bar & Restaurant’s
                                                signature cocktail / mocktail
                                                for two passengers{" "}
                                            </li>
                                        </ul>
                                    </td>
                                    <td>Plaza Premium Lounge</td>
                                </tr>
                                <tr>
                                    <td>ALLWAYS</td>
                                </tr>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">
                                        "Laid-back traveller" type
                                    </td>
                                    <td rowSpan="2">100</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>
                                                Plaza Premium Lounge – Global
                                                Lounge Access for two visits
                                            </li>
                                            <li>
                                                Regal Airport Hotel OM Spa
                                                aromatherapy massage (60
                                                minutes)
                                            </li>
                                        </ul>
                                    </td>
                                    <td>Plaza Premium Lounge</td>
                                </tr>
                                <tr>
                                    <td>Regal Airport Hotel</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <p>
                        <span>
                            The prize redemption will be conducted in an
                            electronic format, in the form of QR codes, coupon
                            codes, e-vouchers and/or e-coupons. The QR codes,
                            coupon codes, e-vouchers and/or e-coupons of the
                            Prizes for each winner are unique and the winners
                            are solely responsible for keeping them safe and
                            secure. If any QR codes, coupon codes, e-vouchers
                            and/or e-coupons is found to have been used when it
                            is presented for using the offers, the Authority
                            will not provide a new QR codes, coupon codes,
                            e-vouchers and/or e-coupons as replacement.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            The Lucky Draw will be conducted by 17 October 2024
                            by a computer system to randomly select 100 winners
                            and reserve winners under each of the five travel
                            styles. If a winner is found to be ineligible, their
                            winning status will be revoked, and a reserve winner
                            will take their place. Winners will be notified
                            individually via Facebook or Instagram inbox
                            messages.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            The winner&rsquo;s list will be announced on our
                            official "Hong Kong International Airport
                            香港國際機場" Facebook page and Instagram account
                            @hongkongairport on 25 October 2024. The Authority
                            will contact the winners through Facebook/Instagram
                            inbox messages for prize redemption arrangement
                            after the winners&rsquo; list is announced. The list
                            of all the winners will be published in Sing Tao
                            Daily and The Standard on 25 October 2024.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            The winners will be notified of the prize redemption
                            details via email within 30 days after being
                            notified of winning the prize. The winner must claim
                            the prize within 30 days after receiving the prize
                            redemption email, otherwise the prize will be
                            considered forfeited.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            The Prizes cannot be exchanged for cash or other
                            gifts. The Prize cannot be transferred, replaced or
                            returned.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            Prizes are subject to the product or service terms
                            and conditions of the relevant prize suppliers and
                            the relevant prize redemption period. Please contact
                            the prize suppliers for details.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            This Lucky Draw is not affiliated with Facebook and
                            Instagram and is neither supported nor managed by
                            Facebook and Instagram.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            In case of any dispute, the Authority reserves the
                            right of final decision.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            The winners represent and warrant that the personal
                            data which he/she provides to the Authority during
                            the Lucky Draw is true, complete and accurate. The
                            Authority shall not be held liable and shall be
                            fully indemnified by the winner(s) for any incorrect
                            and inaccurate personal data provided
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <span>Trade Promotion Competition Licence No.: 59128-32</span>
            </p>
            <h3>
                <span>Personal Information Collection Statement</span>
            </h3>
            <ol>
                <li>
                    <p>
                        <span>
                            Winners will be asked to provide or make available
                            for the Authority&rsquo;s collection the following
                            information:
                        </span>
                    </p>
                    <ol>
                        <li>
                            <p>
                                <span>
                                    The winner&rsquo;s full name (as shown on
                                    the valid proof of identity);
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>Email address</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>Mobile number</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>winner&rsquo;s date of birth; and</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>Social media account names.</span>
                                <span>
                                    <br />
                                    <br />
                                </span>
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <span>
                            The supply of the data is obligatory. If you do not
                            provide the data or the data provided is
                            insufficient, the winning eligibility will be
                            cancelled and you may not be able to redeem any
                            prize .
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            Personal data collected from the winner will be used
                            by the Authority for the following purposes:
                        </span>
                    </p>
                    <ol>
                        <li>
                            <p>
                                <span>
                                    Checking the Participant&rsquo;s eligibility
                                    to participate or to win in the Lucky Draw;
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>Administering the Lucky Draw;</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>
                                    Facilitating future audit of the Lucky Draw;
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>
                                    Notifying the prize winners and the relevant
                                    prize redemption arrangement;
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>
                                    Transferring to the prize suppliers for the
                                    winners identification
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>
                                    (Winner&rsquo;s date of birth) to verify the
                                    winner is 18 years of age or above. If the
                                    winners are under 18 years old, their prize
                                    winning and redemption eligibility will be
                                    cancelled; and
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>
                                    Any other purposes incidental to the
                                    purposes stated above.
                                </span>
                                <span>
                                    <br />
                                    <br />
                                </span>
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <span>
                            The Authority shall keep the winners&rsquo; personal
                            data confidential at all times, and only collect,
                            use and store it in accordance with this Personal
                            Information Collection Statement.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            Personal data of the winner will be kept
                            confidential and will not be disclosed to any third
                            party except for any person or company (including
                            third party agent or sub-contractor) who are acting
                            for or is engaged by the Authority in implementing
                            the Lucky Draw, including communicating with the
                            winners in relation to the Lucky Draw, facilitating
                            the selection and notification of prize winners and
                            the relevant prize redemption arrangement;.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            Our general data retention policy is set out under
                            "How long do we retain your personal data?" section
                            of the General Terms of our Privacy Policy referred
                            to below. Personal data provided to the Authority
                            will be retained by the Authority for 3 months,
                            after which it will be destroyed.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            The winner has the right to access and verify
                            his/her personal data that the Authority holds. A
                            data access request form can be downloaded from the
                            website of the Office of the Privacy Commissioner
                            for Personal Data, Hong Kong.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            The winner may also request the Authority to correct
                            his/her personal data that the Participant has
                            accessed if such data is inaccurate. Please note
                            that under the Personal Data (Privacy) Ordinance,
                            the Authority has a right to charge a reasonable fee
                            for complying with a data access request. Data
                            access and correction requests should be addressed
                            to our General Personal Data Officer, to the address
                            stated below.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            Participants are advised to refer to the
                            Authority&rsquo;s Privacy Policy at
                            www.hongkongairport.com/en/privacy-policy.page for
                            more details. Should Participants have any
                            enquiries, please send an email to
                            MYHKGPROMO@hkairport.com.
                        </span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>
                            If the winner has other questions or wishes to
                            contact the Authority regarding this Personal
                            Information Collection Statement, please contact our
                            General Personal Data Officer via the address below:
                        </span>
                    </p>
                    <p>
                        <span>General Personal Data Officer</span>
                    </p>
                    <p>
                        <span>Airport Authority</span>
                    </p>
                    <p>
                        <span>HKIA Tower</span>
                    </p>
                    <p>
                        <span>1 Sky Plaza Road</span>
                    </p>
                    <p>
                        <span>Hong Kong International Airport</span>
                    </p>
                    <p>
                        <span>Lantau Island</span>
                    </p>
                    <p>
                        <span>Hong Kong</span>
                    </p>
                </li>
            </ol>
            <p>Trade Promotion Competition Licence No.: 59128-32</p>
        </>
    );
};
const TC = () => {
    return <>
            <h1>
                「My HKG - 旅行系咁賞」推廣活動（「活動」）
                <br />條款與細則
            </h1>
            <h3>如何參與「My HKG - 旅行系咁賞」大抽獎推廣活動（「大抽獎」）：</h3>
            <ol>
                <li>透過參加大抽獎，每位參與者確認其已閱讀、明白並同意受本文所載本條款及細則約束，包括任何相關促銷資料中載列的任何其他要求。</li>
                <li>參與活動期限由2024 年 9 月 10 日起至 2024 年 10 月 13 日止（首尾兩日包括在內）。</li>
                <li>如促銷資料中所載資料與本條款及細則之間有任何衝突，則本條款及細則適用，並應以此為準。如本條款及細則的英文版與中文版有任何不一致之處，則以英文版本為準。</li>
                <li>如因大抽獎而對本條款及細則或任何其他事宜有任何爭議，則機場管理局（「本局」）保留最終決定權。本局對該等條款及細則的詮釋及應用或有關大抽獎一切事宜的所有決定，在各種情況下均為最終及決定性的。</li>
                
            </ol>
            <h3>參與資格與方法：</h3>
            <ol>
                <li>參與者在參加大抽獎時必須年滿 18 歲或以上。
                    <ol>
                        <li>步驟 1：使用「My HKG」應用程式完成「我的旅行系」心理測驗試。</li>
                        <li>步驟 2：在自己的 Facebook帳戶上發布公開帖文，或在自己的Instagram 帳戶上發布公開限時動態，分享其測驗結果。</li>
                        <li>步驟3：在該 Facebook帖文或 Instagram 故事中標記兩位朋友，並加入 #mytravelpersonality 主題標籤。Facebook 使用者必須標記我們的官方「Hong Kong International Airport 香港國際機場」Facebook 頁面。Instagram 使用者則必須標記我們的官方 Instagram 帳號 @hongkongairport。</li>
                    </ol>
                </li>
                <li>參與者同意在自己的社交媒體帳戶中發布貼文時，不得發布任何有關種族、性別、性傾向、族裔或其他內在特徵的誹謗、騷擾、辱罵、淫穢、粗俗、色情內容或不恰當內容。此外，嚴禁任何包含虛假或誤導性陳述的內容，以及沒有適當、公平或合理依據的情况下對香港國際機場不利的內容，或可能涉及或潛在涉及非法行為的內容。本局保留剔除違反上述要求的任何內容之權利。</li>
                <li>在宣布得獎名單後，只有得獎者方會收到經收件匣訊息發送的個別通知。得獎者必須提供其姓名及電郵地址，以便安排兌換獎品。下文載列的「收集個人資料聲明及私隱政策」適用。如得獎者在收到通知後48 小時內沒有回覆，或提供的個人資料不正確或不完整，則視作放棄獎品論，該獎品不會重新發放給該得獎者。</li>
                <li>在整個活動期間，每一個社交媒體帳戶僅可贏取一份獎品。</li>
            </ol>
            <h3>取消資格</h3>
            <ol>
                <li>如果你遇到下列任何情況，本局保留唯一且絕對權利取消或排除你參加本大抽獎及／或取消你贏得任何獎品的資格，恕不事先通知：
                    <ol>
                        <li>違反上述條款及細則； </li>
                        <li>提供不正確或不完整的個人資料（如適用）； </li>
                        <li>在獎品領取期間提供的個人資料與參加期間提供的資料不符（如適用）；</li>
                        <li>透過非法或不誠實的方式參加本大抽獎；或 </li>
                        <li>本局認為有必要在合理情況下取消或排除你的資格。</li>
                    </ol>
                </li>
                
                
                
                
                
                
                
                
                
            </ol>
            <h3>獎品及獎品領取程序</h3>
            <ol>
                <li>「My HKG」應用程式的旅行個性測試將提供五種旅行風格給參與者進行測試。每位參與者完成測試後便立即得知結果，分析顯示該五種旅行風格中，哪一種最最符合其個人旅行偏好。在這五種旅行風格中，電腦將從屬於各種旅行風格的參與者中，隨機抽出 100 位得獎者/後備得獎者。</li>
                <li>活動共設有500 份獎品（「獎品」），詳情如下：<br />
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>旅行風格</th>
                                    <th>總數</th>
                                    <th>獎品詳情</th>
                                    <th>供應商</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">「爭分奪秒型」型</td>
                                    <td rowSpan="2">100份</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>環亞機場貴賓室 - 全球貴賓休息室使用權，可供使用兩次</li>
                                            <li>順豐速運上門行李託運服務，可託運兩件行李</li>
                                        </ul>
                                    </td>
                                    <td>環亞機場貴賓室</td>
                                </tr>
                                <tr>
                                    <td>順豐速運</td>
                                </tr>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">「瘋狂爆買」型</td>
                                    <td rowSpan="2">100份</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>環亞機場貴賓室 - 全球貴賓休息室使用權，可供使用兩次</li>
                                            <li>價值500港元的香港國際機場電子現金券</li>
                                        </ul>
                                    </td>
                                    <td>環亞機場貴賓室</td>
                                </tr>
                                <tr>
                                    <td>機場管理局 </td>
                                </tr>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">「商務日常」型</td>
                                    <td rowSpan="2">100份</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>
                                                "環亞機場貴賓室 - 全球貴賓休息室使用權，可供使用兩次
                                            </li>
                                            <li>WiFiBB 全球 4G 漫遊Pocket Wi-Fi ，全球 30 GB 數據</li>
                                        </ul>
                                    </td>
                                    <td>環亞機場貴賓室</td>
                                </tr>
                                <tr>
                                    <td>WiFiBB</td>
                                </tr>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">「食咗先講」型</td>
                                    <td rowSpan="2">100份</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>環亞機場貴賓室 - 全球貴賓休息室使用權，可供使用兩次</li>
                                            <li>ALLWAYS 電動車接送服務，以及 INTERVALS 天際酒吧及餐廳招牌雞尾酒，供兩位旅客享用</li>
                                        </ul>
                                    </td>
                                    <td>環亞機場貴賓室</td>
                                </tr>
                                <tr>
                                    <td>ALLWAYS</td>
                                </tr>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">「漫遊慢活」型</td>
                                    <td rowSpan="2">100份</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>環亞機場貴賓室 - 全球貴賓休息室使用權，可供使用兩次</li>
                                            <li>富豪機場酒店 OM Spa香薰按摩水療體驗（60 分鐘）</li>
                                        </ul>
                                    </td>
                                    <td>環亞機場貴賓室</td>
                                </tr>
                                <tr>
                                    <td>富豪機場酒店</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>獎品兌換將以電子方式進行，包括二維碼、優惠券代碼、電子禮券及／或電子現金券的形式。每名合資格得獎者的獎品二維碼、優惠券代碼、電子禮券及／或電子現金券都是獨一無二的，得獎者應全權負責以確保其妥為保管。如在出示以使用時發現任何二維碼、優惠券代碼、電子禮券及/或電子現金券已被使用，機場管理局將不會提供新的二維碼、優惠券代碼、電子禮券及/或電子現金券作為替代品。</li>

                <li>大抽獎將於 2024 年 10 月 17 日前透過電腦系統根據五種旅行風格各自隨機選出 100 名得獎者及後備得獎者。如發現得獎者不符合資格，其獲獎資格將被撤消，並由後備得獎者代替。得獎者將透過 Facebook 或 Instagram 收件匣訊息收到個別通知。</li>
                <li>得獎者名單將於 2024 年 10 月 25 日前在「Hong Kong International Airport 香港國際機場」官方Facebook 網頁及 Instagram 帳號 @hongkongairport上公布。於公布得獎者名單後，本局將透過 Facebook/Instagram 收件匣訊息與得獎者聯絡，以便安排兌換獎品。所有得獎者名單將在大抽獎程序完成後 10 天內於《星島日報》和《英文虎報》公布。</li>

                <li>得獎者將在收到獲獎通知後30天內透過電郵收到獎品兌換詳情通知。得獎者必須在收到該獎品兌換電郵後 30 天內領取獎品，否則獎品將被視作放棄論。</li>

                <li>獎品不可兌換為現金或其他禮品；亦不得轉讓、更換或退回。</li>
                <li>獎品須根據相關獎品供應商的產品或服務的條款及細則，以及相關獎品兌換期規定。如欲了解詳情，請聯絡獎品供應商。</li>

                <li>本大抽獎與 Facebook及 Instagram 無關，而且Facebook 及 Instagram 概不會提供支援或管理。</li>
                <li>如有任何爭議，本局保留最終決定權。</li>
                <li>得獎者聲明並保證在大抽獎期間，他/她向本局提供的個人資料為真實、完整且準確。對於得獎者提供任何不正確及不準確的個人資料，本局概不承擔責任，並應由得獎者全面彌償。</li>
            </ol>
            <h3>《收集個人資料聲明》</h3>
            <ol>
                <li>得獎者必須提供或讓本局收集下列資料：<br />
                    <ol>
                        <li>得獎者全名（如有效身分證明文件上所示）；</li>
                        <li>電郵地址</li>
                        <li>手提電話號碼</li>
                        <li>得獎者的出生日期，以核實得獎者是否年滿18歲。如得獎者未滿18 歲，則其得獎及兌換獎品資格將被取消；及</li>
                        <li>社交媒體帳戶名稱。</li>
                    </ol>
                </li>
                <li>得獎者必須提供資料。如你不提供資料或所提供的資料不足夠，則其得獎資格將被取消，而且你可能無法兌換任何獎品。</li>
                <li>本局將時刻將得獎者的個人資料保密，並僅根據本《收集個人資料聲明》收集、使用及儲存這些資料。
                    <ol>
                        <li>核實「合資格得獎者」參加及於活動中勝出的資格；</li>
                        <li>管理大抽獎事宜；</li>
                        <li>以助日後對該活動進行審核；</li>
                        <li>通知「合資格得獎者」他們被選為得獎者，以及作相關獎品兌換安排；</li>
                        <li>轉交給獎品供應商以識別得獎者身分；及</li>
                        <li>上述目的附帶的任何其他目的。</li>
                    </ol>
                </li>

                <li>得獎者的個人資料將會保密，除了向代表本局或由本局委聘進行大抽獎（包括與得獎者就大抽獎進行溝通，協助挑選及通知得獎者，以及進行相關獎品兌換安排）的任何人士或公司（包括第三方代理人或分包商）（「第三方服務供應商」）作出披露，將不會向任何第三方作出披露。</li>

                <li>我們的一般資料保留政策已載於我們的《私隱政策》一般條款部分有關「我們會保留你的個人資料多久？」一節。本局將參加者提供的個人資料保留三個月後銷毀。</li>

                <li>得獎者有權查閱及核實本局所持有其個人資料。該等查閱要求表格可從香港個人資料私隱專員公署網站下載。</li>

                <li>如得獎者發現所查閱的個人資料不準確，亦可要求本局改正有關資料。請注意，根據《個人資料（私隱）條例》，本局有權就遵照查閱資料的要求而收取合理費用。有關查閱及改正資料的要求，可寄往以下地址送交我們的一般個人資料主任。</li>

                <li>參與者參閱本局的《私隱政策》 (www.hongkongairport.com/tc/privacy-policy.page) 以了解更多詳情。如參與者有任何疑問，請發送電郵至MYHKGPROMO@hkairport.com。</li>

                <li>如得獎者有其他問題或有意就《收集個人資料聲明》聯絡，請透過以下地址聯絡我們的一般個人資料主任：
                    <br />
                    香港大嶼山<br />
                    香港國際機場<br />
                    翔天路 1 號<br />
                    機場行政大樓<br />
                    機場管理局<br />
                    一般個人資料主任
                </li>
            </ol>
            <br />
            <br />
            推廣生意的競賽牌照號碼：59128-32。
        </>
    ;
};
const SC = () => {
    return (
        <>
            <h1>
                「My HKG - 旅行系咁賞」推广活动（「活动」）
                <br />
                条款与细则
            </h1>
            <h3>
                如何参与「My HKG - 旅行系咁賞」大抽奖推广活动（「大抽奖」）：
            </h3>
            <ol>
                <li>
                    通过参加大抽奖，每位参与者确认其已阅读、明白并同意受本文所载本条款及细则约束，包括任何相关促销资料中载列的任何其他要求。
                </li>
                <li>
                    参与活动期限由2024年9月10日起至2024年10月13日止（首尾两天包括在内）。
                </li>
                <li>
                    如促销资料中所载资料与本条款及细则之间有任何冲突，则本条款及细则适用，并应以此为准。如本条款及细则的英文版与中文版有任何不一致之处，则以英文版本为准。
                </li>
                <li>
                    如因大抽奖而对本条款及细则或任何其他事宜有任何争议，则机场管理局（「本局」）保留最终决定权。本局对该等条款及细则的诠释及应用或有关大抽奖一切事宜的所有决定，在各种情况下均为最终及决定性的。
                </li>
            </ol>
            <h3>参与资格与方法：</h3>
            <ol start="5">
                <li>参与者在参加大抽奖时必须年满18岁或以上。</li>
                <li>
                    要符合参加大抽奖资格，参与者必须完成下列所有三个步骤（「合格参与者」）：
                    <ol>
                        <li>
                            步骤1：使用「My
                            HKG」应用程式完成「我的旅行是」心理测验试。
                        </li>
                        <li>
                            步骤2：在自己的Facebook帐户上发布公开帖文，或在自己的Instagram帐户上发布公开限时动态，分享其测验结果。
                        </li>
                        <li>
                            步骤3：在该Facebook帖文或Instagram故事中标记两位朋友，并加入#mytravelpersonality主题标签。Facebook使用者必须标记我们的官方「Hong
                            Kong
                            InternationalAirport香港国际机场」Facebook页面。Instagram使用者则必须标记我们的官方Instagram帐号@hongkongairport。
                        </li>
                    </ol>
                </li>
                <li>
                    参与者同意在自己的社交媒体帐户中发布贴文时，不得发布任何有关种族、性别、性倾向、族裔或其他内在特征的诽谤、骚扰、辱骂、淫秽、粗俗、色情内容或不恰当内容。此外，严禁任何包含虚假或误导性陈述的内容，以及没有适当、公平或合理依据的情况下对香港国际机场不利的内容，或可能涉及或潜在涉及非法行为的内容。本局保留剔除违反上述要求的任何内容之权利。
                </li>
                <li>
                    在宣布得奖名单后，只有得奖者方会收到经收件匣讯息发送的个别通知。得奖者必须提供其姓名及电邮地址，以便安排兑换奖品。下文载列的「收集个人资料声明及私隐政策」适用。如得奖者在收到通知后48小时内没有回复，或提供的个人资料不正确或不完整，则视作放弃奖品论，该奖品不会重新发放给该得奖者。
                </li>
                <li>在整个活动期间，每一个社交媒体帐户仅可赢取一份奖品。</li>
            </ol>
            <h3>取消资格</h3>
            <ol start="10">
                <li>
                    如果你遇到下列任何情况，本局保留唯一且绝对权利取消或排除你参加本大抽奖及/或取消你赢得任何奖品的资格，恕不事先通知：
                    <ol>
                        <li>违反上述条款及细则; </li>
                        <li>提供不正确或不完整的个人资料（如适用）;</li>
                        <li>
                            在奖品领取期间提供的个人资料与参加期间提供的资料不符（如适用）;
                        </li>
                        <li>通过非法或不诚实的方式参加本大抽奖;或</li>
                        <li>本局认为有必要在合理情况下取消或排除你的资格。</li>
                    </ol>
                </li>
            </ol>
            <h3>奖品及奖品领取程序</h3>
            <ol start="11">
                <li>
                    「My
                    HKG」应用程式的旅行个性测试将提供五种旅行风格给参与者进行测试。每位参与者完成测试后面立即得知结果，分析显示该五种旅行风格中，哪一种最最符合其个人旅行偏好。在这五种旅行风格中，电脑将从属于各种旅行风格的参与者中，随机抽出100位得奖者/后备得奖者。
                </li>
                <li>
                    活动共设有500份奖品（「奖品」），详情如下：
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>旅行风格</th>
                                    <th>总数</th>
                                    <th>奖品详情</th>
                                    <th>供应商</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">「争分夺秒型」型</td>
                                    <td rowSpan="2">100份</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>
                                                环亚机场贵宾室─全球贵宾休息室使用权，可供使用两次
                                            </li>
                                            <li>
                                                顺丰速运上门行李托运服务，可托运两件行李"
                                                环亚机场贵宾室
                                            </li>
                                        </ul>
                                    </td>
                                    <td>环亚机场贵宾室</td>
                                </tr>
                                <tr>
                                    <td>顺丰速运</td>
                                </tr>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">「疯狂爆买」型</td>
                                    <td rowSpan="2">100份</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>
                                                环亚机场贵宾室─全球贵宾休息室使用权，可供使用两次
                                            </li>
                                            <li>
                                                价值500港元的香港国际机场电子现金券
                                            </li>
                                        </ul>
                                    </td>
                                    <td>环亚机场贵宾室</td>
                                </tr>
                                <tr>
                                    <td>机场管理局</td>
                                </tr>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">「商务日常」型</td>
                                    <td rowSpan="2">100份</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>
                                                环亚机场贵宾室─全球贵宾休息室使用权，可供使用两次
                                            </li>
                                            <li>
                                                WiFiBB全球4G漫游Pocket
                                                Wi-Fi，全球30 GB数据
                                            </li>
                                        </ul>
                                    </td>
                                    <td>环亚机场贵宾室</td>
                                </tr>
                                <tr>
                                    <td>WiFiBB</td>
                                </tr>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">「吃了再说」型</td>
                                    <td rowSpan="2">100份</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>
                                                环亚机场贵宾室─全球贵宾休息室使用权，可供使用两次
                                            </li>
                                            <li>
                                                ALLWAYS电动车接送服务，以及INTERVALS天际酒吧及餐厅招牌鸡尾酒，供两位旅客享用
                                            </li>
                                        </ul>
                                    </td>
                                    <td>环亚机场贵宾室</td>
                                </tr>
                                <tr>
                                    <td>ALLWAYS</td>
                                </tr>
                                {/* row */}
                                <tr>
                                    <td rowSpan="2">「漫游慢活」型 </td>
                                    <td rowSpan="2">100份</td>
                                    <td rowSpan="2">
                                        <ul>
                                            <li>环亚机场贵宾室─全球贵宾休息室使用权，可供使用两次</li>
                                            <li>富豪机场酒店OM Spa香薰</li>
                                        </ul>
                                    </td>
                                    <td>环亚机场贵宾室</td>
                                </tr>
                                <tr>
                                    <td>富豪机场酒店</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    奖品兑换将以电子方式进行，包括二维码、优惠券代码、电子礼券及/或电子现金券的形式。每名合资格得奖者的奖品二维码、优惠券代码、电子礼券及/或电子现金券都是独一无二的，得奖者应全权负责以确保其妥为保管。如在出示以使用时发现任何二维码、优惠券代码、电子礼券及/或电子现金券已被使用，机场管理局将不会提供新的二维码、优惠券代码、电子礼券及/或电子现金券作为替代品。
                </li>
                <li>
                    大抽奖将于2024年10月17日前通过电脑系统根据五种旅行风格各自随机选出100名得奖者及后备得奖者。如发现得奖者不符合资格，其获奖资格将被撤消，并由后备得奖者代替。得奖者将通过Facebook或Instagram收件匣讯息收到个别通知。
                </li>
                <li>
                    得奖者名单将于2024年10月25日前在「Hong Kong International
                    Airport香港国际机场」官方Facebook网页及Instagram帐号@hongkongairport上公布。於公布得奖者名单后，本局将通过Facebook/Instagram收件匣讯息与得奖者联络，以便安排兑换奖品。所有得奖者名单将在大抽奖程序完成后10天内於《星岛日报》和《英文虎报》公布。
                </li>
                <li>
                    得奖者将在收到获奖通知后30天内通过电邮收到奖品兑换详情通知。得奖者必须在收到该奖品兑换电邮后30
                    天内领取奖品，否则奖品将被视作放弃论。
                </li>
                <li>奖品不可兑换为现金或其他礼品;亦不得转让、更换或退回。</li>
                <li>
                    奖品须根据相关奖品供应商的产品或服务的条款及细则，以及相关奖品兑换期规定。如欲了解详情，请联络奖品供应商。
                </li>
                <li>
                    本大抽奖与Facebook及Instagram无关，而且Facebook及Instagram概不会提供支援或管理。
                </li>
                <li>如有任何争议，本局保留最终决定权。</li>
                <li>
                    得奖者声明并保证在大抽奖期间，他/她向本局提供的个人资料为真实、完整且准确。对于得奖者提供任何不正确及不准确的个人资料，本局概不承担责任，并应由得奖者全面弥偿。
                </li>
            </ol>
            <h3>《收集个人资料声明》</h3>
            <ol>
                <li>
                    得奖者必须提供或让本局收集下列资料：
                    <ol>
                        <li>得奖者全名（如有效身分证明文件上所示）; </li>
                        <li>电邮地址 </li>
                        <li>手提电话号码</li>
                        <li>
                            得奖者的出生日期，以核实得奖者是否年满18岁。如得奖者未满18岁，则其得奖及兑换奖品资格将被取消;及
                        </li>
                        <li>社交媒体帐户名称。</li>
                    </ol>
                </li>
                <li>
                    得奖者必须提供资料。如你不提供资料或所提供的资料不足够，则其得奖资格将被取消，而且你可能无法兑换任何奖品。
                </li>
                <li>
                    本局向得奖者收集的个人资料将用于下列用途：
                    <ol>
                        <li>核实「合资格得奖者」参加及于活动中胜出的资格;</li>
                        <li>管理大抽奖事宜;</li>
                        <li>以助日后对该活动进行审核;</li>
                        <li>
                            通知「合资格得奖者」他们被选为得奖者，以及作相关奖品兑换安排;
                        </li>
                        <li>转交给奖品供应商以识别得奖者身分;及</li>
                        <li>上述目的附带的任何其他目的。</li>
                    </ol>
                </li>
                <li>
                    本局将时刻将得奖者的个人资料保密，并仅根据本《收集个人资料声明》收集、使用及储存这些资料。
                </li>
                <li>
                    得奖者的个人资料将会保密，除了向代表本局或由本局委聘进行大抽奖（包括与得奖者就大抽奖进行沟通，协助挑选及通知得奖者，以及进行相关奖品兑换安排）的任何人士或公司（包括第三方代理人或分包商）（「第三方服务供应商」）作出披露，将不会向任何第三方作出披露。
                </li>
                <li>
                    我们的一般资料保留政策已载于我们的《私隐政策》一般条款部分有关「我们会保留你的个人资料多久？」一节。本局将参加者提供的个人资料保留三个月后销毁。
                </li>
                <li>
                    得奖者有权查阅及核实本局所持有其个人资料。该等查阅要求表格可从香港个人资料私隐专员公署网站下载。
                </li>
                <li>
                    如得奖者发现所查阅的个人资料不准确，亦可要求本局改正有关资料。请注意，根据《个人资料（私隐）条例》，本局有权就遵照查阅资料的要求而收取合理费用。有关查阅及改正资料的要求，可寄往以下地址送交我们的一般个人资料主任。
                </li>
                <li>
                    参与者参阅本局的《隐私政策》（www.hongkongairport.com/sc/privacy-policy.page）以了解更多详情。如参与者有任何疑问，请发送电邮至
                    MYHKGPROMO@hkairport.com。
                </li>
                <li>
                    如得奖者有其他问题或有意就《收集个人资料声明》联络，请透过以下地址联络我们的一般个人资料主任：
                    <br />
                    香港大屿山
                    <br />
                    香港国际机场
                    <br />
                    翔天路1号
                    <br />
                    机场行政大楼
                    <br />
                    机场管理局
                    <br />
                    一般个人资料主任
                    <br />
                </li>
            </ol>
            <br />
            <br />
            推广生意的竞赛牌照号码：59128-32。
        </>
    );
};

export { EN, TC, SC };
